@import "../_global/fonts";
@import "../_global/colors";

label {
  width: 100%;
}
.constructor__form {
  fieldset {
    margin-bottom: 15px;
  }

  .field {
    margin-bottom: 30px;

    &__row {
      display: flex;
      align-items: center;
      margin-top: 15px;
    }

    &__strong {
      font-weight: 700;
    }

    &__button {
      margin-left: 10px;
      width: calc(2.25rem + 2px);
      height: calc(2.25rem + 2px);
      border-radius: 50%;
      font-size: 30px;
      background: #fff;
      border: 2px solid #dbdbed;
      line-height: 30px;
      transition: 0.2s;

      &--add {
        color: $primary;

        &:hover {
          background: #d1dbff;
          border: 2px solid #d1dbff;
        }
      }

      &--del {
        background-image: url("../../img/raster/close.png");
        background-position: center center;
        background-repeat: no-repeat;
        cursor: pointer;
        &:hover {
          background: #edd0dc;
          border: 2px solid #edd0dc;
        }
      }
      &--square {
        cursor: pointer;
        border-radius: 5px;
        align-self: flex-start;
        width: 25px;
        height: 25px;
        border: none;
        flex-shrink: 0;
      }
    }

    &__image-block {
      display: flex;
    }

    &__image {
      margin-top: 5px;
      display: flex;
      padding: 15px;
      border: 1px solid #cbcfdd;
      border-radius: 10px;

      img {
        width: 50px;
        height: 50px;
        padding: 5px;
        border-radius: 5px;
        object-fit: scale-down;
      }
      span {
        align-self: center;
        margin-left: 5px;
      }
    }

    &__warning {
      margin-top: 10px;

      .warning__blue {
        color: $primary;
      }
    }

    .select2 {
      //width: 100% !important;
      &--hidden {
        // display: flex;
        // justify-content: space-between;
        // border-radius: 0.25rem;
        // cursor: text;
        // height: calc(2.25rem + 2px);
        // padding: 0.375rem 100px;
        // font-size: 1rem;
        // line-height: 1.5;
        // color: #495057;
        // background-color: #fff;
        // background-clip: padding-box;
        // border: 1px solid #ced4da;

        // &-accessible {
        //   width: 0 !important;
        // }
      }
    }
  }
  .table {
    .b-t {
      border-top: 1px solid #fff;
    }

    .b-r {
      border-right: 1px solid #fff;
    }
    .inner {
      width: 46%;
      padding: 5px;
    }

    .td-small {
      width: 27%;
      padding: 5px;
    }
    .td-middle {
      width: 50%;
      padding: 5px;
    }
    .td-grey {
      color: #4a4a4a;
    }

    &__td {
      width: 15%;
      padding: 5px;

      &--narrow {
        width: 5%;
        padding: 5px;
      }

      &--large {
        display: flex;
        max-width: 20%;
      }
      &--number2 {
        width: 10%;
      }
      &--xlarge {
        width: 30%;
      }
      &--middle {
        width: 50%;
        padding: 5px;
      }
    }

    &__header {
      display: flex;
      background: $table;

      .table__td {
        &--bottom {
          width: 100%;
          display: flex;
          padding: 0;
        }

        &--xlarge {
          padding: 0;
          width: 30%;
        }

        &--padding {
          width: 100%;
          padding: 5px;
        }
      }
      &--fixed {
        height: 50px;
        .table__td {
          display: flex;
          align-items: center;
          &--xlarge {
            padding: 10px;
          }
        }
      }
    }
    &__footer {
      display: flex;
      background: #dad9eb;
      border-top: 1px solid #fff;
      .table__td {
        &--xlarge {
          display: flex;
          padding: 0;
        }
      }
    }

    &__row {
      display: flex;
      .table__td {
        border: 1px solid $table;
        padding: 3px;
        &--small {
          width: 8.1%;
        }
        &--right-border {
          border-right: 1px solid #dbdaec !important;
        }

        &--middle {
          width: 15.1%;
        }
        &.disabled {
          border: unset;
          background: #dad9eb;
          border: 1px solid #fff;
          textarea {
            background: #dad9eb;
          }
        }
      }
      &.chosen {
        background: #f4f2f9;
        .table__textarea {
          //background: #f4f2f9;
        }
      }
    }
    &__input {
      width: 100%;
      border: none;
      outline: none;
      border-bottom: 1px solid transparent;
    }
    &__textarea {
      width: 100%;
      border: none;
      outline: none;
      border-bottom: 1px solid transparent;
      resize: none;
      overflow: auto;
      background: transparent;
    }
  }
  .actions {
    margin-bottom: 5px;
    display: flex;
    justify-content: flex-end;
    &--margin {
      margin-bottom: 30px;
    }
    &__button {
      display: flex;
      justify-content: center;
      font-size: 13px;
      margin-bottom: 5px;
      margin-right: 5px;
      line-height: 18px;
      cursor: pointer;
      width: min-content;
      padding: 5px;
      border-radius: 15px;
      transition: 0.2s;

      span {
        margin-right: 5px;
        font-size: 20px;
        line-height: 18px;
        font-weight: bold;
      }

      &--add {
        span {
          color: $primary;
        }
        &:hover {
          background: #d1dbff;
        }
      }
      &--remove {
        span {
          color: #000;
        }
        &:hover {
          background: #edd0dc;
        }
      }
    }
  }
  .file-row {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    .file-label {
      margin-right: 13px;
      margin-bottom: 0.5em;
    }
    .file-upload {
      input[type="file"] {
        display: none;
      }
      width: 164px;
      height: 31px;
      background: #ccd0de;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 15px;
      cursor: pointer;
      color: #fff;
      font-size: 12px;
      font-weight: bold;
      padding-right: 5px;
      &:hover {
        background: $primary;
      }
      &__img {
        width: 18px;
        height: 18px;
        margin-right: 5px;
      }
    }
  }
  .js-control {
    display: none;
    color: transparent;
  }
  .cke {
    width: 100%;
  }
  .width-35 {
    width: calc(100% - 35px);
  }
}

.upload-btn {
  position: absolute;
  width: 164px;
  height: 31px;
  background: #7696ff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  cursor: pointer;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  padding-right: 5px;
}

.profile_wrapper > div {
  position: relative;
  width: 750px;
  margin: 0 auto;
  & > label {
    font-size: 16px;
    color: #363963;
    font-family: "Arimo";
    font-weight: 400;
  }
  .select2-container {
    width: 100% !important;
  }
  button {
    display: inherit !important;
    margin: 0 auto;
  }
  .buttons-control__button {
    margin-right: auto !important;
  }
  input[type="file"] {
    display: none;
  }
}

.file-row-profile {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  margin-left: 8px;
}

.file-label-profile {
  //margin-right: 13px;
  margin-left: -5px;
}

.file-upload-profile {
  width: 164px;
  height: 31px;
  background: #7696ff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  cursor: pointer;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  padding-right: 5px;
  input {
    display: none;
  }
  &:hover {
    background: darken(#7696ff, 10%);
  }
}

.buttons-control-profile {
  position: relative;
  display: flex;
  margin-bottom: 100px;
  justify-content: center;

  &__button {
    margin-right: 15px;
    padding: 10px 15px;
    border-radius: 20px;
    font-size: 12px;
    font-weight: 700;

    &--clear {
      background: transparent;
      color: #363963;
      border: 2px solid #dbdbed;

      &:hover {
        background: #dbdbed;
      }
    }

    &--save {
      background-color: #17184d;
      color: #fff;
      border: 2px solid #17184d;
      user-select: none;

      &:hover {
        background: lighten(#17184d, 20%);
        border: 2px solid lighten(#17184d, 20%);
      }
    }
  }
}

.profile-arrow {
  position: relative;
    width: 35px;
    height: 35px;
    padding: 5px;
    flex-shrink: 0;
    background: $primary;
    cursor: pointer;
    transition: .2s;
    &--required {
      &:after {
        display: table-cell;
        position: absolute;
        width: 10px;
        height: 10px;
        content: '';
        background: no-repeat center url(../../img/vector/star.svg);
        background-size: contain;
        right: -13px;
        bottom: 13px;
      }
    }

    img {
      transition: .5s;
      transform: rotate(-180deg);
    }
    &.active {
      img {
        transform: rotate(0);
      }
    }
    &:focus, &:hover {
      background: darken($primary, 20%);
    }
}

.checkboxes-profile {
  display: grid;
  grid-template-columns: 1fr 1fr;
  //grid-auto-rows: 2em;
  //grid-gap: .5em;
  padding: 20px 30px 20px 60px;
  background: #e7e6fa;
  .checkbox {
    margin-bottom: .5em;
    margin-right: 1em;
  }
}

.filter-flex {
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  .custom-control-label:before {
    border: 0.5px solid #000 !important;
  }
  // .custom-control-label:after {
  //   border: 0.5px solid #000 !important;
  // }
}

.fix-width {
  width: 287px;
}

.expertise-filter-wrapper {
  width: 100%;
  &__upper {
    display: flex;
    margin-bottom: 10px;
  }
  &__lower {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
}

.submit-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
  margin-bottom: 50px;
}

.RecoverPassword {
  position: relative;
  display: grid;
  grid-template-columns: 9.3% 1fr 9.3%;
  max-width: 1400px;
  margin: 0 auto;
  padding-top: 137px;
  padding-bottom: 200px;
  z-index: 4;
  @media screen and (max-width: 720px) {
    grid-template-columns: 4% 1fr 4%;
  }
  &__container {
    position: relative;
    margin: 0 auto;
    padding-top: 117px;
    background: #fff;
  }
  &__title {
    margin-bottom: 37px;
    font-size: 52px;
    color: #000b93;
    font-family: "Montserrat";
    font-weight: 700;
  }
  &__text {
    margin-bottom: 42px;
    font-size: 16px;
    line-height: 22px;
    color: #363963;
    font-family: "Arimo";
    font-weight: 400;
    text-align: center;
  }
  &__form-login {
      max-width: 500px;
      margin: 0 auto;
  }
  .form-container {
    background-color: #ededfd;
  }
  .lower-container-recover {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 6px;
  }
  .submit-recover {
    position: relative;
    display: block;
    padding: 10px 20px 10px 20px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    font-family: "Arimo";
    color: #fff;
    background-color: #0817d0;
    border: 1px solid #0817d0;
    border-radius: 25px;
    outline: none;
    transition: 0.3s;
    &:hover {
      background-color: #4c579a;
      border: 1px solid #4c579a;
    }
  }
}

.Header__container {
  position: relative;
  margin: 0 auto;
}

.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto!important;
  .form-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: center;
    padding: 45px;
    background-color: #ededfd;
    min-width: 260px;
  }
  .form-title {
    position: relative;
    margin-bottom: 26px;
    font-size: 20px;
    line-height: 18px;
    color: #000b93;
    font-family: "Arimo";
    font-weight: 700;
    text-transform: uppercase;
  }
  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
  .form-label {
    margin-bottom: 9px;
    font-size: 16px;
    color: #363963;
    font-family: "Arimo";
    font-weight: 400;
  }
  .form-input {
    display: block;
    width: 100%;
    padding: 9px 0;
    // margin-bottom: 22px;
    outline: none;
    font-size: 16px;
    font-weight: 400;
    text-indent: 2%;
    border: 1px solid #dbdbed;
    border-radius: 5px;
  }
  .checkbox:not(checked) {
    position: absolute;
    z-index: -1;
    opacity: 0;
    margin: 10px 0 0 7px;
  }
  .form-label-checkbox {
    position: relative;
    padding: 0 0 0 60px;
    cursor: pointer;
  }
  .lower-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 6px;
    .forget-pass {
      font-size: 14px;
      color: #363963;
      font-family: "Arimo";
      font-weight: 400;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        color: #0817d0;
      }
    }
  }
  .submit-container {
    position: relative;
    .submit-icon {
      position: absolute;
      content: "";
      width: 25px;
      height: 25px;
      left: 20px;
      top: 8px;
      z-index: 10;
    }
    .submit {
      position: relative;
      display: block;
      padding: 10px 23px 10px 56px;
      cursor: pointer;
      font-size: 16px;
      font-weight: 700;
      font-family: "Arimo";
      color: #fff;
      background-color: #0817d0;
      border: 1px solid #0817d0;
      border-radius: 25px;
      outline: none;
      transition: 0.3s;
      &:hover {
        background-color: #4c579a;
        border: 1px solid #4c579a;
      }
    }
  }
}

.input-container {
  margin-bottom: 22px;
}

.is-danger {
  border: 2px solid #faa86c !important;
}

.form-error {
  margin: 0;
  font-size: 14px;
  line-height: 18px;
  color: #fe8e15;
  font-family: "Arimo";
  font-weight: 400;
}

.Header-container {
  position: relative;
  max-width: 1400px;
  margin: 0 auto;
  // border: 0.1px solid black;
  z-index: 4;
  .main_title_container {
    display: grid;
    grid-template-columns: 9.3% 1fr;
    grid-row-gap: 19px;
    @media screen and (max-width: 768px) {
      grid-template-columns: 4% 1fr;
    }
    .main_title_image_container {
      display: grid;
      grid-template-columns: 165px 0.5fr;
      grid-gap: 19px;
      align-items: flex-end;
      @media screen and (max-width: 585px) {
        grid-template-columns: 120px 1fr;
        grid-gap: 10px;
      }
      @media screen and (max-width: 470px) {
        grid-template-columns: 95px 1fr;
        grid-gap: 10px;
      }
      @media screen and (max-width: 425px) {
        grid-template-columns: 70px 1fr;
        grid-gap: 10px;
      }
      .title_image {
        width: 165px;
        height: 200px;
        @media screen and (max-width: 720px) {
          width: 144px;
          height: 175px;
        }
        @media screen and (max-width: 585px) {
          width: 100px;
          height: 129px;
        }
        @media screen and (max-width: 470px) {
          width: 90px;
          height: 119px;
        }
        @media screen and (max-width: 425px) {
          width: 70px;
          height: 90px;
        }
      }
    }
  }
}

.main_title {
  font-size: 50px;
  line-height: 54px;
  color: #fff;
  font-family: "Montserrat";
  font-weight: 700;
  @media screen and (max-width: 720px) {
    font-size: 45px;
    line-height: 48px;
  }
  @media screen and (max-width: 670px) {
    font-size: 40px;
    line-height: 43px;
  }
  @media screen and (max-width: 525px) {
    font-size: 33px;
    line-height: 46px;
  }
  @media screen and (max-width: 470px) {
    font-size: 27px;
    line-height: 30px;
  }
  @media screen and (max-width: 425px) {
    font-size: 22px;
    line-height: 24px;
  }
  &__lower-text {
    grid-column-start: 2;
    grid-column-end: 3;
    width: 300px;
    margin-left: 50px;
    font-size: 20px;
    line-height: 26px;
    color: #fff;
    font-family: "Arimo";
    font-weight: 400;
    @media screen and (max-width: 720px) {
      font-size: 18px;
      line-height: 23px;
    }
    @media screen and (max-width: 425px) {
      margin-left: 80px;
      font-size: 14px;
      line-height: 19px;
      width: auto;
    }
  }
}

.Header__container {
  position: relative;
  margin: 0 auto;
}

.Header__background-image {
  background: url("../../img/raster/bg-top.png");
  background-repeat: no-repeat;
  // position: absolute;
  // top: -220px;
  // left: 50%;
  // transform: translateX(-50%);
  // object-fit: none;
  // object-position: center;
  position: absolute;
  top: -420px;
  object-fit: none;
  object-position: center;
  height: 1110px;
  width: 100%;
  background-position: center;
  z-index: 3;
  @media screen and (max-width: 1380px) {
    top: -395px;
  }
  @media screen and (max-width: 1210px) {
    top: -358px;
  }
  @media screen and (max-width: 1056px) {
    top: -345px;
  }
  @media screen and (max-width: 880px) {
    top: -322px;
  }
  @media screen and (max-width: 585px) {
    top: -370px;
  }
  @media screen and (max-width: 425px) {
    top: -420px;
  }
  @media screen and (max-width: 320px) {
    top: -471px;
    background-position-x: -1165px;
  }
}

.About {
  position: relative;
  display: grid;
  grid-template-columns: 50% 1fr 9.3%;
  max-width: 1400px;
  margin: 0 auto;
  padding-top: 137px;
  padding-bottom: 55px;
  @media screen and (max-width: 745px) {
    grid-template-columns: 50% 1fr 4%;
  }
  @media screen and (max-width: 720px) {
    padding-top: 187px;
  }
  @media screen and (max-width: 650px) {
    grid-template-columns: 4% 1fr 4%;
  }
  @media screen and (max-width: 425px) {
    padding-top: 140px;
  }
  @media screen and (max-width: 375px) {
    padding-top: 130px;
  }
  @media screen and (max-width: 320px) {
    padding-top: 80px;
  }
  &__container {
    position: relative;
    // width: 2000px;
    margin: 0 auto;
    margin-top: 117px;
    @media screen and (max-width: 1024px) {
      margin-top: 200px;
    }
    @media screen and (max-width: 880px) {
      margin-top: 210px;
    }
  }
  &__background-image {
    position: absolute;
    top: -10px;
    left: 30px;
    object-fit: none;
    object-position: center;
    z-index: -1;
    @media screen and (max-width: 1182px) {
      left: -25px;
    }
    @media screen and (max-width: 1075px) {
      left: -80px;
    }
    @media screen and (max-width: 970px) {
      left: -135px;
    }
    @media screen and (max-width: 860px) {
      left: -190px;
    }
    @media screen and (max-width: 745px) {
      left: -205px;
    }
    @media screen and (max-width: 720px) {
      left: -160px;
      top: 37px;
    }
    @media screen and (max-width: 720px) {
      left: -208px;
    }
    // @media screen and (max-width: 560px) {
    //   left: -240px;
    // }
    // @media screen and (max-width: 500px) {
    //   left: -240px;
    // }
    @media screen and (max-width: 650px) {
      display: none;
    }
  }
  &__background-image_man {
    position: absolute;
    top: 289px;
    left: 20%;
    object-fit: none;
    object-position: center;
    z-index: 1;
    @media screen and (max-width: 1182px) {
      left: 16%;
    }
    @media screen and (max-width: 970px) {
      left: 11%;
    }
    @media screen and (max-width: 860px) {
      left: 6%;
    }
    @media screen and (max-width: 720px) {
      left: 11%;
      top: 340px;
    }
    @media screen and (max-width: 650px) {
      display: none;
    }
  }
  &__grid {
    display: grid;
    grid-gap: 35px;
  }
  &__title {
    font-size: 54px;
    color: #000b93;
    font-family: "Montserrat";
    font-weight: 700;
    @media screen and (max-width: 670px) {
      font-size: 40px;
      line-height: 43px;
    }
    @media screen and (max-width: 425px) {
      font-size: 30px;
      line-height: 36px;
    }
  }
  &__p {
    margin-top: -22px;
    font-size: 16px;
    line-height: 24px;
    color: #363963;
    font-family: "Arimo";
    font-weight: 400;
    @media screen and (max-width: 425px) {
      font-size: 14px;
      line-height: 19px;
    }
  }
}

.detail-btn {
  margin-top: -10px;
  padding: 8px 30px;
  font-size: 16px;
  line-height: 18px;
  color: #43466d;
  font-family: "Arimo";
  font-weight: 700;
  background: transparent;
  border-radius: 25px;
  outline: 0;
  border: 2px solid #dbdbed;
  cursor: pointer;
}


.Header {
  position: relative;
  z-index: 5;
  display: grid;
  grid-template-columns: 9.3% 1fr 311px 9.3%;
  grid-template-rows: 1fr 1fr;
  grid-row-gap: 16px;
  max-width: 1400px;
  margin: 0 auto;
  padding-top: 45px;
  padding-bottom: 45px;
  //   background-color: #ff086a;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr 550px 1fr 260px 1fr;
  }
  @media screen and (max-width: 850px) {
    grid-template-columns: 4% 480px 1fr 253px 4%;
  }
  @media screen and (max-width: 805px) {
    grid-template-columns: 4% 430px 1fr 253px 4%;
  }
  @media screen and (max-width: 742px) {
    grid-template-columns: 4% 389px 1fr 250px 4%;
  }
  @media screen and (max-width: 695px) {
    grid-template-columns: 2% 1fr 2%;
    padding-bottom: 30px;
    padding-top: 30px;
    grid-row-gap: 0px;
  }
  @media screen and (max-width: 425px) {
    grid-template-columns: 2% 1fr 2%;
    padding-bottom: 30px;
  }
  &__navigation {
    display: grid;
    grid-template-columns: 100px 159px 359px 211px;
    // margin-left: -11.5%;
    text-align: center;
    text-decoration: underline;
    @media screen and (max-width: 1024px) {
      margin-left: -11%;
    }
    @media screen and (max-width: 742px) {
      grid-template-columns: 26% 1fr 1fr;
      margin-left: -3%;
      text-align: center;
    }
    @media screen and (max-width: 695px) {
      display: none;
    }
    @media screen and (max-width: 425px) {
      display: none;
    }
    .about,
    .registry,
    .catalog {
      color: #fff;
      cursor: pointer;
      &:hover {
        color: #ffbc4b;
      }
    }
  }
  .fake-div {
    @media screen and (max-width: 695px) {
      display: none;
    }
  }
  &__login {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: center;
    margin-right: -3.5%;
    cursor: pointer;
    @media screen and (max-width: 695px) {
      margin-right: 0;
    }
    @media screen and (max-width: 425px) {
      margin-right: 0;
    }
    .user-icon {
      display: none;
      width: 35px;
      @media screen and (max-width: 695px) {
        display: block;
      }
    }
    .burger-menu {
      display: none;
      width: 35px;
      justify-self: flex-end;
      @media screen and (max-width: 695px) {
        display: block;
      }
    }
  }
  &__search {
    display: grid;
    grid-template-columns: 1fr 9.3%;
    // grid-template-columns: 29.5% 1fr 9.3%;
    position: relative;
    grid-column-start: 2;
    grid-column-end: 5;
    align-items: center;
    @media screen and (max-width: 695px) {
      grid-column-end: 3;
    }
    @media screen and (max-width: 425px) {
      grid-column-end: 3;
    }
    .categorie_select {
      position: relative;
      display: grid;
      grid-template-columns: 1fr 3fr;
      align-items: center;
      // position: absolute;
      width: 195px;
      height: 100%;
      background-color: #113bc9;
      border-bottom-left-radius: 30px;
      border-top-left-radius: 30px;
      text-align: center;
      cursor: pointer;
      @media screen and (max-width: 425px) {
        display: none;
      }
      &:after {
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        border-top: 28.5px solid transparent;
        border-left: 15px solid #113bc9;
        border-bottom: 28.5px solid transparent;
        right: -15px;
      }
      .categorie_select_name {
      }
      .icon-container {
        text-align: right;
        .categorie_select_icon {
          width: 20px;
          margin-top: 1px;
        }
      }
    }
    .search-field {
      width: 100%;
      height: 55px;
      // border-radius: 30px;
      border-bottom-right-radius: 30px;
      border-top-right-radius: 30px;
      border-bottom-left-radius: 30px;
      border-top-left-radius: 30px;
      color: #fff;
      border: 0.1px solid #2131b1;
      background-color: #2131b1;
      //   padding: 0 10px;
      text-indent: 5%;
      vertical-align: middle;
      outline: none;
    }
    .search-icon {
      position: absolute;
      width: 25px;
      top: 14px;
      right: 135px;
      cursor: pointer;
    }
  }
  .registration {
    color: #ffbc4b;
    @media screen and (max-width: 695px) {
      display: none;
    }
  }
  .fade-animation {
    animation: fadein 3.886s;
  }
}

.enter-btn {
  margin-top: -10px;
  padding: 8px 30px;
  font-size: 16px;
  line-height: 18px;
  color: #ffffff;
  font-family: "Arimo";
  font-weight: 700;
  background: transparent;
  border-radius: 25px;
  outline: 0;
  border: 2px solid #ffbc4b;
  cursor: pointer;
  @media screen and (max-width: 695px) {
    display: none;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fos_user_resetting_reset .form-control,
.fos_user_resetting_request .form-control {
  padding: 0;
  padding-left: 10px;
  padding-right: 10px;
}

.delete-btn-info {
  background: inherit;
  border: none;
  width: 20px;
  padding: 0;
}

.catalog-file-name {
  color: #6e6faf;
  margin-bottom: 5px;
}

.download-file-title {
  display: block;
  margin-bottom: 10px;
}

.download-file-list {
  margin-bottom: 5px;
}

.delete-btn-catalog-list {
  width: 20px;
  height: 20px;
}

.organizations__header {
  color: #000b93;
  font-size: 26px;
  margin-top: 20px;
}
