.archive {
  padding: 40px 0 60px;

  a {
    cursor: pointer;
  }

  &__block {
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;

    &:first-of-type {
      margin-top: 40px;
    }
  }

  &__label-wrapper {
    width: 100%;
    display: flex;
  }

  &__rubric {
    color: #3b4256;
    font-family: $roboto;
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    text-decoration: underline;
    margin-top: 20px;
    width: 100%;
    margin-bottom: 0;
  }

  &__mediateka {
    margin-top: 20px;
    color: #3d4669;
    font-family: $open-sans;
    font-size: 14px;
    line-height: 20px;
  }

  &__list {
    margin: 20px 130px 0;

    &-item {
      display: flex;
      flex-wrap: wrap;
      color: #3d4669;
      font-family: $open-sans;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 15px;

      &:last-of-type {
        margin-bottom: 0;
      }

      &_small {
        margin-bottom: 5px;
      }

      &_name, &_position {
        width: 100%;
      }

      &_name {
        text-decoration: underline;
      }

      &_date {
        color: #86909b;
        font-size: 12px;
        line-height: 22px;
        margin-top: 5px;
      }

      &_days {
        color: #3b4256;
        font-size: 16px;
        font-weight: 700;
        line-height: 18px;
        margin-bottom: 5px;
      }
    }
  }

  .news-announce__title {
    margin-bottom: 35px;
    width: 100%;
  }

  @media screen and (max-width: 1350px){
    .section-wrapper {
      padding: 0;
      margin: 0 45px;
    }

    &__list {
      margin: 20px 64px 0;
    }
  }

  @media screen and (max-width: 575px) {
    & {
      padding: 28px 0 45px;
    }

    .section-wrapper {
      margin: 0 22px;
    }

    .news-announce__title {
      font-size: 28px;
      line-height: 34px;
      margin-bottom: 20px;
    }

    .page-title {
      font-size: 30px;
      line-height: 36px;
    }

    &__block {
      margin-top: 33px;

      &:first-of-type {
        margin-top: 0;
      }
    }

    &__mediateka, &__rubric {
      margin-top: 0;
    }

    &__list {
      margin: 15px 0 0;
    }
  }
}