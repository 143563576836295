.footer {
  padding: 50px 0 30px;
  background: #142973;
  color: #80a0d6;
  position: relative;

  &__row {
    display: flex;
    align-items: start;
    justify-content: space-between;
  }

  &__row-2 {
    display: flex;
    margin-top: 40px;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  &__col-1 {
    width: 320px;
    padding: 0 15px;
    @media screen and (max-width: 767px) {
      //width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__col-2 {
    width: 80px;
    @media screen and (max-width: 767px) {
      width: 100%;
      text-align: left;
      padding-left: 30%;
      margin-top: 40px;
    }
  }

  &__col-3 {
    width: 200px;
    @media screen and (max-width: 767px) {
      width: 100%;
      text-align: left;
      padding-left: 30%;

    }
  }

  &__col-4 {
    width: 360px;
    margin-right: 15px;
    @media screen and (max-width: 991px) {
      width: 100%;
      margin-top: 50px;
      margin-right: 0;

    }

  }

  &__credentials {
    width: 54%;

    p {
      margin: 0;
    }
  }

  &__copyright {
    width: 28%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 1200px) {
      width: 35%;
    }

    p {
      margin: 0;
    }

    &__text {
      width: 85%;
    }

    &__sign {
      div {
        border: 2px solid rgba(128, 160, 214, .8);
        width: 35px;
        height: 35px;
        border-radius: 50%;
        font-size: 12px;
        color: rgba(128, 160, 214, .8);
        padding: 8px 3px 8px 5px;
        font-weight: bold;
      }
    }
  }

  @media screen and (max-width: 991px) {
    &__row-2 {
      flex-wrap: wrap;
    }
    &__credentials {
      width: 85%;
      margin-bottom: 30px;
    }
    &__copyright {
      width: 90%;
      //justify-content: flex-start;
      //
      &__text {
        width: 90%;
      }
    }
  }

  &__text {
    font-size: 12px;
    line-height: 15px;
  }

  &__logo-link {
    position: relative;
    display: inline-block;

  }

  &__logo {
    fill: #fff;
    width: 271px;
    height: 75px;
  }

  &__magazine-text {
    font-family: $roboto;
    font-weight: 500;
    line-height: 1.29;
    text-transform: uppercase;
    text-align: right;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 10px;
    width: 100px;

  }

  &__eagle-row {
    display: flex;
    align-items: center;
  }

  &__ministry {
    color: #a4b4ec;
    font-size: 11px;
    font-weight: 700;
    line-height: 1.8;
    text-transform: uppercase;
    margin-left: 15px;
  }
  &__numbers-block {
    margin-bottom: 50px;
  }

  &__text-link {
    color: #80a0d6;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.45;
    text-transform: uppercase;
    display: block;
    margin-bottom: 7px;
    @at-root {
      a#{&} {
        &:hover {
          color: lighten(#80a0d6, 15);
        }
      }
    }
    &_email {
      text-transform: none;
      text-decoration: underline;
    }
  }

}