.js-select-type-1 + {
  .select2-container--default .select2-selection--single {
    height: 44px;
    border: 2px solid #32499a;
    background-color: #142973;
    font-size: 13px;
    outline: none;
  }
  .select2-container--default.select2-container--open .select2-selection--single {
    border: 2px solid #32499a;
    background-color: #32499a;
  }


  .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #80a0d6;
    line-height: 38px;
  }

  .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 44px;
    position: absolute;
    top: 1px;
    right: 1px;
    width: 30px;
    background: url("/img/raster/select-arrow.png") 50% 50%no-repeat;
    transition: all .2s;
  }
  .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow {
    transform: rotate(180deg);
  }
  .select2-container--default .select2-selection--single .select2-selection__arrow b {
    display: none;
  }
  .select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: rgba(128, 160, 214, 0.6);
  }
}

.select-type-1-dropdown {
  color: #80a0d6;
  font-size: 13px;
  &.select2-dropdown {
    border: 2px solid #32499a;
    background-color: #1f3686;
  }
  .select2-search--dropdown .select2-search__field {
    background-color: #1f3686;
    border: 2px solid #32499a;
    color: #80a0d6;
    outline: none;
  }
  .select2-results__option--highlighted[aria-selected] {
    background: lighten(#1f3686, 5);
  }
  .select2-results__option[aria-selected=true] {
    background: lighten(#1f3686, 15);
  }
}



/********/

.js-select-type-2 + {
  .select2-container--default .select2-selection--single {
    height: 44px;
    border: 2px solid #c6d3e5;
    background-color: #fff;
    font-size: 13px;
    outline: none;
  }
  .select2-container--default.select2-container--open .select2-selection--single {
    border: 2px solid #c6d3e5;
    background-color: #fff;
  }


  .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #3b4256;
    line-height: 38px;
  }

  .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 44px;
    position: absolute;
    top: 1px;
    right: 1px;
    width: 30px;
    background: url("/img/raster/select-arrow-green.png") 50% 50% no-repeat;
    transition: all .2s;
  }
  .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow {
    transform: rotate(180deg);
  }
  .select2-container--default .select2-selection--single .select2-selection__arrow b {
    display: none;
  }
}

.select-type-2-dropdown {
  color: #3b4256;
  font-size: 13px;
  &.select2-dropdown {
    border: 2px solid #c6d3e5;
    background-color: #fff;
  }
  .select2-search--dropdown .select2-search__field {
    background-color: #fff;
    border: 2px solid #c6d3e5;
    color: #3b4256;
    outline: none;
  }
  .select2-results__option--highlighted[aria-selected] {
    background: lighten(#3b4256, 5);
  }
  .select2-results__option[aria-selected=true] {
    background: lighten(#3b4256, 50);
    margin-bottom: 0;
  }
  .select2-results__option {
    margin-bottom: 0;
  }

}

