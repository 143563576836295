@import "../_global/colors";
.program {
  &__link {
    color: #000b93;
    text-decoration: underline;
  }

  &__info {
    position: fixed;
    border: 3px solid #d2d1df;
    border-radius: 7px;
    background: antiquewhite;
    max-width: 293px;
    padding-top: 21px;

    left: 657px;
    z-index: 2;
  }

}

.education {
  &__block {
    margin-top: 30px;
    border: 1px solid #d6d6d6;
    padding: 20px;
  }
  &__links {
    display: flex;
  }
  &__link {
    position: relative;
    padding-right: 20px;
    color: dimgrey;
    font-size: 14px;
    &:not(:first-child) {
      &:after {
        content: '>';
        position: absolute;
        top: 1px;
        left: -14px;
        width: 20px;
        height: 20px;
      }
    }
  }
  &__doc {
    font-size: 18px;
  }
  &__docs {
    margin-top: 20px;
    padding: 20px;
    background: #fbfbfb;
    border-radius: 5px;
    display: flex;
    align-items: center;
    box-shadow: 0 1px 3px rgba(0,0,0,.5);

    a {
      margin-left: 20px;
      display: flex;
      align-items: center;
      color: #3e3e3e;
      &:hover, &:active {
        text-decoration: underline;
      }
    }
    img {
      width: 40px;
      height: 40px;
    }

  }
  &__expertise {
    filter: grayscale(1) brightness(1.5);
    &.active {
      filter: unset;
    }
  }
  &__create {
    margin: 50px 0;
  }
  &__table {
    display: flex;
    margin-top: 30px;
    padding-top: 20px;
    border-top: 1px solid #cccccc;
  }
  .table {
    &__column {
      display: flex;
      align-items: center;
      &--1, &--2, &--3 {
        //width: 22%;
      }
      &--4 {
        width: 34%;
      }
    }
    &__row {
      text-align: center;
      color: grey;
      margin-bottom: 10px;
      &--header {
        //margin-bottom: 30px;
      }
      &--start {
        text-transform: uppercase;
        text-decoration: none;
        font-size: 14px;
        margin-left: 10px;
        font-weight: bold;
        color: #7696ff;
        border-radius: 5px;
          &.active {
            padding: 10px;
            color: #ffffff;
            background: #17184c;
            &:hover {
              background: #252884;
            }
          }

      }
    }
  }
  .disabled {
    pointer-events: none;
    filter: grayscale(1);
  }
}