.report-card {
  &__empty {
    display: flex;
    margin-bottom: 5px;
    border: 1px solid #e9e9f4;
  }
  &__upper-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    border: 1px solid #e9e9f4;
    .end-tests {
      display: flex;
    }
    .material-info {
      width: 286px;
      padding-left: 15px;
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 14px;
      line-height: 18px;
      color: #17184d;
      font-family: "Arimo";
      font-weight: 400;
      & > div {
        margin-bottom: 5px;
      }
    }
    .check-status {
      width: 200px;
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 14px;
      line-height: 18px;
      color: #7696ff;
      font-family: "Arimo";
      font-weight: 400;
      & > div {
        margin-bottom: 5px;
      }
    }
    .check-time {
      width: 155px;
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 12px;
      color: #b7b6d7;
      font-family: "Arimo";
      font-weight: 400;
      & > div {
        margin-bottom: 5px;
      }
    }
  }
  .upper-title {
    display: flex;
    align-items: center;
    width: 190px;
    padding-left: 15px;
    background: #dbdbed;
    font-size: 12px;
    color: #17184d;
    font-family: "Arimo";
    font-weight: 700;
    text-transform: uppercase;
    .upper-title-span {
    }
  }
}

.report-user-role {
  font-size: 12px;
  color: #b7b6d7;
  font-family: "Arimo";
  font-weight: 400;
}

.report-user-name {
  margin-bottom: 3px;
  font-size: 18px;
  color: #616082;
  font-family: "Arimo";
  font-weight: 400;
}

.test-info {
  display: flex;
  align-items: center;
  .material-info {
    width: 510px;
    padding-left: 8px;
    padding-bottom: 5px;
    padding-right: 110px;
  }
}

.report-status {
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  line-height: 28px;
  color: #363963;
  font-family: "Arimo";
  font-weight: 400;
}
