.section-title {
  color: #3b4256;
  font-family: $roboto;
  font-size: 28px;
  font-weight: 900;
  line-height: 1.21;
  margin-bottom: 25px;

  &_blue {
    color: #142973;
  }

  &_light-blue {
    color: #a4b3e6;
  }

}

.page-title {
  color: #667294;
  font-family: $roboto;
  font-size: 44px;
  font-weight: 400;
  line-height: 48px;
  margin-bottom: 30px;
}

.page-title-type-2 {
  color: #3b4256;
  font-family: $roboto;
  font-size: 44px;
  font-weight: 900;
  line-height: 1.09;
  margin-bottom: 30px;
  @media screen and (max-width: 575px) {
    font-size: 30px;
  }
}

.no-image {
  background-size: unset !important;
  background-color: #C6D3E5 !important;
  background-repeat: no-repeat;
}

.no-image.opinion-slide__img {
  background-size: contain !important;
}

.label.label_fat {
  line-height: 1.75;
  height: 41px;
}

.archive-wrapper {
  padding: 60px 0;
  background: #eaeff6;
}

.archive-section {
  background: #eaeff5;
  padding: 20px 0 60px;

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.with-padding-left {
  padding-left: 70px !important;
}

.archive-month-title {
  color: #3b4256;
  font-family: $roboto;
  font-size: 44px;
  font-weight: 900;
  line-height: 1.09;
}

.breadcrumbs2 {
  line-height: 1.5;
  a {
    color: $regent_gray;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &:after {
      content: '/';
      display: inline-block;
      margin: 0 10px;
      color: $main-topic;
    }
  }

  span {
    color: $regent_gray;
    text-decoration: none;
  }
}