@import "../_global/fonts";
@import "../_global/colors";

$grey:  #b2b2b7;

form .form-group {
  //display: flex;
  //align-items: center;
}
.my-programs {
  display: flex;
  flex-flow: column nowrap;
  margin-top: 44px;

  &__create-program {
    margin-left: auto;
    margin-right: 5px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 700;
    line-height: 1em;
    height: 30px;
    padding: 11px;
    border-radius: 15px;
    transition: .2s;

    &:hover, &:focus {
      background: #f1f3ff;
    }

    span {
      margin-right: 4px;
      font-size: 31px;
      font-weight: 400;
      color: $primary;
    }
  }

  .program {
    margin-bottom: 25px;
    padding: 24px 27px;
    font-size: 14px;
    border: 1px solid #f1f1f1;
    background: #f5f5f563;

    .flex-end {
      justify-content: flex-end;
      button {
        //margin-top: 40px;
        padding: 7px 15px;
        background: #bdbdc5;
        border: none;
        border-radius: 30px;
        color: #fff;
        font-weight: bold;
        font-size: 13px;
        transition: .2s;
        &:hover {
          background: #857f8d;
        }
      }
    }

    &__id {
      color: $grey;
    }
    &__path {
      margin-top: 27px;
    }
    &__title {
      margin-top: 27px;
      color: #363963;
      font-size: 22px;
    }
    &__head {
      display: flex;
      //justify-content: space-between;
      .head-block {
        &__cell {
          color: $grey;
          text-align: center;
          margin: 28px 20px 28px 0;
        }
      }
    }

    &__name {
      color: #838383;
      padding: 14px 0;
      //margin-top: 18px;
      //border-top: 1px solid #f1f1f1;
      border-bottom: 1px solid #f1f1f1;
      &.flex {
        display: flex;
        justify-content: space-between;
      }
      .region {
        color: #363963;
      }
    }
    &__table {
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;

      //overflow-x: scroll;
      //width: auto;
      //height: auto;
      &-row {
        display: flex;
        flex-flow: row nowrap;
        margin-top: 20px;
        //justify-content: space-between;
      }
    }
    &__column {
      width: calc((100% - 300px) / 3);
      &--creation {
        width: 150px;
      }
      &--version {
        width: 150px;
        margin-right: 20px;
      }
    }
    &__row {
      display: flex;
      //justify-content: space-between;
      margin-bottom: 28px;
      .program__cell {
        &--status {
          text-transform: uppercase;
          color: #000b93;
          font-weight: 700;
          line-height: 18px;
          font-size: 12px;
        }
        &--creation {
          color: #17184d;
        }
        &--version {
          color: $primary;
        }
      }
    }
    &__cell {
      text-align: center;
      margin-right: 20px;
      &--status {
        width: 100px;
      }
      &--creation {
        width: 100px;
      }
      &--checkings {
        width: 110px;
      }
      &--version {
        width: 100px;
      }
      &--expert {
        width: 140px;
      }
      &--empty {
        color: $grey;
      }
      &--left {
        text-align: left;
      }
      &--head {
        color: grey;
        margin-bottom: 20px;
      }
      &--blue {
        color: $primary;
      }
      &--expert-head {
        text-align: left;
        margin-left: 17px;
      }
    }
    &__controls {
      display: flex;
      justify-content: space-between;
      margin-top: 50px;
    }
    &--expert {

    }
    .cell-block {
      &__name {
        position: relative;
        //margin-left: 25px;
        margin-bottom: 10px;
        font-size: 16px;
        color: #363963;

        &::before {
          position: absolute;
          top: -3px;
          left: -23px;
          content: '';
          width: 19px;
          height: 19px;
        }
        &--ok {
          &::before {
            background-image: url("../../img/vector/ok.svg");
            background-size: contain;
            background-position: center;
          }
        }
        &--no {
          &::before {
            background-image: url("../../img/vector/close.svg");
            background-size: contain;
            background-position: center;
          }
        }
      }
      &__date {
        color: #a8a8ad;
        position: relative;
        margin-left: 25px;
        font-size: 14px;

        &::before {
          position: absolute;
          top: -3px;
          left: -23px;
          content: '';
          width: 19px;
          height: 19px;
          background-image: url("../../img/vector/document.svg");
          background-size: contain;
          background-position: center;
        }
      }
    }
    .buttons-block {
      display: flex;
      &__button {
        margin-left: 15px;
        height: 34px;
        display: flex;
        align-items: center;
        background-color: #bdbdc5;
        border: none;
        border-radius: 20px;
        color: #fff;
        padding: 10px;
        font-size: 12px;
        font-weight: 700;
        transition: .2s;
        &:hover, &:focus {
          background: $primary;
        }
        &--blue {
          background:  $primary;
          &:hover, &:focus {
            background: darken($primary, 10%);
          }
        }
        &--delete {
          background: #d28686;
          &:hover, &:focus {
            background: darken(#d28686, 10%);
          }
        }
        &--dark-blue {
          background: #17184c;
          &:hover, &:focus {
            background: darken($primary, 10%);
          }
        }
        &--disabled {
          background: #e1dfea;
          &:hover, &:focus {
            background: #e1dfea;
          }
        }
      }
      &__img {
        width: 18px;
        height: 18px;
      }
    }
    .coauthor-block {
      &__title {
        margin-bottom: 10px;
        color: $grey;
      }
    }

  }
  .link {
    position: relative;
    margin-right: 15px;
    color: $primary;
    &--bold {
      font-weight: 700;
    }
    &:not(:first-child):before {
      position: absolute;
      content: '>';
      color: $primary;
      font-weight: 400;
      left: -13px;
      top: 0;
    }
    &:hover {
      text-decoration: underline;
    }
  }
}
.program {
  form {
    display: flex;
    flex-flow: column nowrap;
  }
  .form-order-flex {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    align-items: center;
    //justify-content: space-between;
    order: 98;
    padding: 20px 0;
    border-top: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;
    a {
      margin-right: 0;
    }
    &__block {
      margin: 15px 0;
    }
  }
  .expertise.custom-input-file {
    order: 97;
    position: relative;
    text-align: center;
    margin-top: 40px;
    font-size: 16px;
    input {
      opacity: 0;
      height: 1px;
    }
    label {
      &::after {
        content: '';
        position: absolute;
        z-index: -1;
        display: table;
        top: -9px;
        left: calc(50% - 130px);
        width: 260px;
        height: 40px;
        background: #fff;
        border: 2px solid grey;
        border-radius: 30px;
        padding-top: 9px;
      }
      &:hover {
        &::after {
          border: 2px solid $primary;
        }
      }
    }
  }
  .js-file-name {
    position: relative;
    order: 97;
    margin-bottom: 20px;
    padding-left: 30px;
    .file-name-logo {
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        background: url("../../img/vector/ok-green.svg");
        background-size: cover;
      }
    }
  }
}
@media all and (max-width: 1240px){

}
