.svg-arrow-max-dims {
	width: 27px;
	height: 49px;
}

.svg-arrow-min-dims {
	width: 5.6px;
	height: 9.4px;
}

.svg-calendar-dims {
	width: 17.5px;
	height: 16.2px;
}

.svg-close-lg-dims {
	width: 28px;
	height: 28px;
}

.svg-doc-doc-dims {
	width: 24.416px;
	height: 31.631px;
}

.svg-doc-odt-dims {
	width: 24.417px;
	height: 31.632px;
}

.svg-doc-pdf-dims {
	width: 24.417px;
	height: 31.632px;
}

.svg-doc-video-dims {
	width: 24.4px;
	height: 31.6px;
}

.svg-doc-xls-dims {
	width: 24.416px;
	height: 31.631px;
}

.svg-doc-zip-dims {
	width: 24.416px;
	height: 31.631px;
}

.svg-download-dims {
	width: 28px;
	height: 24.7px;
}

.svg-hamburger-dims {
	width: 34.16px;
	height: 31px;
}

.svg-icon-pdf-dims {
	width: 24.417px;
	height: 31.632px;
}

.svg-lens-glass-dims {
	width: 26.654px;
	height: 26.622px;
}

.svg-look-dims {
	width: 20px;
	height: 13.5px;
}

.svg-main-logo-dims {
	width: 368.355px;
	height: 100.321px;
}

.svg-photo-dims {
	width: 512px;
	height: 512px;
}

.svg-print-dims {
	width: 14px;
	height: 14px;
}

.svg-quotes-dims {
	width: 20px;
	height: 18.68px;
}

.svg-search-dims {
	width: 26.7px;
	height: 26.6px;
}

.svg-share-dims {
	width: 18.9px;
	height: 24px;
}

.svg-video-dims {
	width: 314.1px;
	height: 314.1px;
}

