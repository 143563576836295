.form-filter {
  margin-top: 45px;
  .search {
    position: relative;
    &__btn {
      position: absolute;
      top: 0;
      right: 10px;
      width: 35px;
      height: 37px;
      border: none;
      background: transparent;
    }
  }
  .custom-radio .custom-control-input:checked ~ .custom-control-label {
    &::after {
      top: 4px;
      left: -20px;
      border-radius: 50%;
      width: .5rem;
      height: .5rem;
      background: #7d92ff;
    }
    &::before {
      border: #adb5bd solid 1px;
      background-color: #fff;
    }

  }
  &__row {
    display: flex;
    align-items: center;
    margin-top: 20px;
    input[type=submit] {
      background: #17184c;
      border: none;
      padding: 5px 20px;
      color: #fff;
      border-radius: 18px;
      outline: none;
      &:hover, &:active, &:focus {
        background: lighten(#17184c, 10%);
      }
    }
  }
  &__title {
    flex-shrink: 0;
    margin-right: 20px;
  }
  &__select {
    width: 400px !important;
    &--last {
      &+.select2-container {
        margin-left: 30px;
      }
    }
  }
}
.pagination {
  justify-content: center;
  margin-top: 30px;
  .page-item {
    a {
      color: #3150b5;
    }
    &.active {
      .page-link {
        background-color: #7696ff;
        border-color: #7696ff;
      }
    }
  }
}