.section {
  margin: 0 10px 0 20px;
  &__header {
    font-size: 32px;
    line-height: 35px;
    color: #000b93;
    margin-bottom: 45px;
    padding-left: 10px;
  }
}
