%svg-common {
	background: url("/img/vector/sprite-css.svg") no-repeat;
}

.svg-logo_nath {
	@extend %svg-common;
	background-position: 40.354698195395144% 99.61427193828351%;
}

.svg-logo_nath-dims {
	width: 82.6px;
	height: 103.3px;
}

.svg-nath-1 {
	@extend %svg-common;
	background-position: 61.53560830860533% 0;
}

.svg-nath-1-dims {
	width: 134.4px;
	height: 103.3px;
}

.svg-nath-2 {
	@extend %svg-common;
	background-position: 0 0;
}

.svg-nath-2-dims {
	width: 165.9px;
	height: 103.3px;
}

.svg-nath-3 {
	@extend %svg-common;
	background-position: 99.86697705354175% 0;
}

.svg-nath-3-dims {
	width: 103.3px;
	height: 103.3px;
}

.svg-nath-4 {
	@extend %svg-common;
	background-position: 0 99.61427193828351%;
}

.svg-nath-4-dims {
	width: 129.7px;
	height: 103.3px;
}

