.gallery {
  margin-bottom: 40px;

  .swiper-container {
    width: 100%;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  .swiper-slide {
    background-size: cover;
    background-position: center;
    height: 404px;
    border-radius: 5px;
    @media screen and (max-width: 767px) {
      height: 202px;
    }
  }

  .gallery-top {
    height: 80%;
    width: 100%;
  }

  .gallery-thumbs {
    height: 20%;
    box-sizing: border-box;
    padding: 5px 0;
  }

  .gallery-thumbs .swiper-slide {
    opacity: 0.4;
    background-color: #eee;
    height: 137px;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    border-radius: 5px;
    @media screen and (max-width: 767px) {
      height: 80px;
    }
  }

  .gallery-thumbs .swiper-slide-thumb-active {
    opacity: 1;
  }

  &__btn {
    top: 0;
    bottom: 0;
    width: 5%;
    background: transparent;
    height: 100%;
    margin-top: 0;
    fill: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .2s;

    &:hover {
      background: rgba(7, 25, 89, 0.6);

      svg {
        opacity: 1;
        visibility: visible;
      }
    }

    svg {
      width: 11px;
      height: 20px;
      opacity: 0;
      visibility: hidden;
    }

    &_next {
      right: 0;
      border-radius: 0 5px 5px 0;
    }

    &_prev {
      left: 0;
      border-radius: 5px 0 0 5px;
    }
  }
  &__img-view {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

