.cabinet {
    &__menu-container {
        display: flex;
        justify-content: center;
    }
    &__menu__item {
        padding: 9px 30px;
        text-align: center;
        &__a {
            font-size: 22px;
            line-height: 42px;
            font-family: "Roboto Condensed";
            font-weight: 400;
            color: #464646;
        }
    }
    &__competition-course {
        display: flex;
        margin-bottom: 60px;
    }
}

.menu-full-width {
    width: 100%;
    margin-top: -15px;
    margin-bottom: 16px;
    border-bottom: 2px solid #e4e4e4;
}