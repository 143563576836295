.my-programs, form[name="expertise_list_form"] {
  textarea {
    display: none;
    width: 100%;
    margin-bottom: 20px;
    border: 1px solid #cecece;
    border-radius: 3px;
    padding: 10px;
    &.visible {
      display: block;
    }
  }
  input[type=radio] {
    display: none;

    & + label {
      position: relative;

      &::before {
        position: absolute;
        top: 0px;
        left: -30px;
        content: '';
        width: 20px;
        height: 20px;
        background: #fff;
        border: 1px solid #b7b7b7;
        border-radius: 50%;
      }

      &:hover {
        color: darken(#3d4669, 20%);
      }
    }

    &:checked {
      & + label {
        position: relative;

        &::after {
          position: absolute;
          top: 5px;
          left: -25px;
          content: '';
          background: #7696ff;
          width: 10px;
          height: 10px;
          border-radius: 50%;
        }
      }
    }
  }
  button[type=submit] {
    padding: 5px 15px;
    margin-top: 20px;
    border: none;
    background: #7696ff;
    color: #fff;
    font-size: 16px;
    border-radius: 5px;
    &:hover, &:active {
      background: #6783df;
    }
  }

  .program {
    &__question {
      margin-top: 30px;
      border-bottom: 1px solid #eaeaea;
      &:last-of-type {
        display: none;
      }
      &+ div {
        position: relative;
        &.required {
          &::after {
            display: table-cell;
            position: absolute;
            width: 10px;
            height: 10px;
            content: '';
            background: no-repeat center url(../../img/vector/star.svg);
            background-size: contain;
            right: -13px;
            top: calc(50% - 18px);
          }
        }
      }
    }

    &__preview {
      width: 130px;
      background: #d6d6d6;
      text-align: center;
      padding: 5px;
      border-radius: 20px;
      cursor: pointer;
      margin-left: auto;
      margin-bottom: 34px;

      &:hover {
        background: #c7c7c7;
      }
    }
  }


  .question__radio {
    display: flex;
    justify-content: flex-end;
    margin-right: -11px;

    label {
      width: 36px;
      margin-left: 30px;
    }
  }

  .question__title {
    margin-bottom: 5px;
  }

}

.check {
  text-align: center;
  margin-bottom: 50px;
  margin-top: 50px;

  &-button {
    margin-top: 50px;
    margin-bottom: 30px;
    //margin-left: calc(50% - 50px);
    background: #17174c;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    font-size: 12px;
    color: #fff;
  }
}

.expertise__status {
  color: grey;
  display: flex;
  align-items: center;
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
  }
}

.custom-control-label-position:before {
  top: 2px;
}

.fake-checkbox:after {
  position: absolute;
  top: 5px;
  left: -21px;
  content: '';
  background: center no-repeat url(../../img/vector/select_arrow.svg);
  background-size: cover;
  width: 10px;
  height: 10px;
  z-index: 10;
}