@import "../_global/fonts";
@import "../_global/colors";

.constructor {
  &__control {
    display: flex;
    justify-content: space-between;
  }

  &__buttons {
    display: flex;
  }

  &__button {
    height: 34px;
    cursor: pointer;
    margin-right: 10px;
    padding: 0 20px 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #cfcfdb;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    border-radius: 20px;
    //transition: .2s;

    &:hover {
      background: $primary;
      color: #fff;
    }

    &-image {
      width: 25px;
      height: 19px;
      margin-top: -2px;
    }
  }

  &__steps {
    position: relative;
    margin-top: 20px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
  }

  &__line {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 22px;
    background-color: #dedee5;
  }

  &__step {
    cursor: pointer;
    width: 160px;
    text-align: center;
    line-height: 14px;
    color: #17184d;
    font-family: $arimo;
    font-weight: 700;
    font-size: 11px;
    text-transform: uppercase;

    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    &--small {
      width: 100px;
    }
    &:hover {
      .constructor__circle {
        background: #c5d2ff;
        color: #fff;
        border: 1px solid #c5d2ff;
      }
      .constructor__content {
        color: #4f64aa;
      }
    }
    &.active {
      .constructor__circle {
        border: 1px solid transparent;
        background: $primary;
        color: #fff;
      }
      .constructor__content {
        color: $primary;
      }
    }
  }

  &__section {
    width: 100%;
    height: 22px;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #8c8ca5;
    background-color: #dedee5;
  }

  &__circle {
    margin-top: 15px;
    width: 54px;
    height: 54px;
    color: #b7b6d7;
    border: 1px solid #b7b6d7;
    border-radius: 50%;
    background: #fff;
    font-size: 28px;

    display: flex;
    align-items: center;
    justify-content: center;

    //transition: .2s;
  }

  &__content {
    margin-top: 10px;
  }

  &__form-wrap {
    display: flex;
    margin-top: 15px;
  }
  &__form {
    margin-top: 15px;
    margin-right: 20px;
    margin-bottom: 50px;
    padding: 30px 25px;
    flex-grow: 2;
    border: 1px solid #d2d2d2;
    h2 {
      margin-bottom: 1.5rem;
    }
  }
  &__tips {
    margin-left: auto;
    width: 179px;
    flex-shrink: 0;
  }
}

.step3_title {
  font-size: 16px;
  line-height: 18px;
  color: #363963;
  font-family: "Arimo";
  font-weight: 700;
}

.actions__button {
  font-size: 12px;
  line-height: 16px;
  color: #363963;
  font-family: "Arimo";
  font-weight: 700;
  text-transform: uppercase !important;
}
