@import url('https://fonts.googleapis.com/css?family=Arimo:400,400i,700,700i&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Fira+Sans+Condensed:400,700&display=swap');

@import "_global/**/*.scss";
@import "vendor/**/*.scss";
@import "vendor-customs/**/*.scss";
@import "pages/**/*.scss";
@import "blocks/**/*.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Arimo", "Montserrat", "Fira Sans Condensed", 
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  line-height: 1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
