@import "../_global/colors";
.statistic {
  padding: 20px;
  max-width: 100vw;
  input[type=submit] {
    background: #5279ff;
    color: #fff;
    &:hover, &:active, &:focus {
      background: darken(#5279ff, 10%);
    }
  }
  .dashboard {
    &-region {
      height: 20px;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
    &-name {
      text-align: right;
      margin-right: 20px;
      width: 200px;
    }
    &-count {
      position: relative;
      background: #ececec;
      width: calc(100% - 300px);
      height: 100%;
    }
    &-fill-wrapper {
      height: 100%;
    }
    &-fill {
      background: #527aff;
      height: 100%;
      width: 0;
      animation: increase 2s forwards;
    }
    &-sign {
      position: absolute;
      top: 0;
      left: 102%;
    }
  }
  .margin-left {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: -8px;
    width: 205px;
    height: 50px;
  }
  .table {
    margin-top: 20px;
    border: 1px solid #cdd3d9;
    th {
      background: #3F51B5;
      text-align: center;
      color: #fff;
      font-weight: 500;
      border-bottom: none;
      border-top: none;
      vertical-align: middle;
    }
    td {
      border-top: none;
      vertical-align: middle;
    }
    tr:nth-child(2n) {
      background: #f6f6ff;
    }
    tr td:first-child {
      text-align: center;
      color: #5279ff;
    }
    .color-blue {
      color: #7d92ff;
    }
    &--center {
      td {
        text-align: center;
      }
    }
    &--inner {
      width: 100%;
      padding: 0;
      tr:nth-child(2n) {
        background: unset;
      }
      tr td:first-child {
        text-align: center;
        color: unset;
      }
    }
  }
  .expertise {
    &-filter-wrapper__upper {
      justify-content: space-between;
      margin-bottom: 15px;
    }
    &-filter-wrapper__lower {
      margin-bottom: 15px;
    }
    &__side {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: flex-end;
      &:first-child {
        margin-right: -10px;
      }
    }
  }
  .label-calendar {
    position: relative;
    width: auto;
    margin-bottom: 0;
    margin-left: 10px;
    display: flex;
    img {
      position: absolute;
      right: 5px;
      top: 7px;
    }
  }
  .submit-wrapper {
    margin-bottom: unset;
  }
  &-menu {
    margin-top: 30px;
    display: flex;
    a {
      width: 25%;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: 1px solid #ced4da;
      border-bottom: 1px solid #ced4da;
       &:not(:last-child) {
         border-left: 1px solid #ced4da;
       }
       &:last-child {
         border-right: 1px solid #ced4da;
         border-left: 1px solid #ced4da;
       }
      &:hover, &:active, &:focus, &.active {
        background: #f6f6ff;
      }
      &:active, &:focus, &.active {
        background: $primary;
        color: #fff;
      }

    }

  }
  &.form-filter {
    background: #f1f1f1;
    margin-top: 0;
    width: 1000px;
    opacity: 0;
  }
  &__header {
    padding-left: 20px;
    color: #000b93;
    font-size: 26px;
    margin-top: 20px;
  }

}
.hasDatepicker {
  width: 150px;
  margin-left: 10px;
}
.ui-datepicker {
  background: rgb(255, 255, 255);
  padding: 10px;
  border: 1px solid #ececec;
  //&-prev span {
  //  background-image: url(http://legacy.australianetwork.com/img/icon_arrow_left_black.png) !important;
  //  background-position: 0px 0px !important;
  //}
  //&-next span {
  //  background-image: url(http://legacy.australianetwork.com/img/icon_arrow_right_black.png) !important;
  //  background-position: 0px 0px !important;
  //}
  &-calendar {
    th {
      padding: 0 2px;
    }
    td {
      text-align: right;
      margin: 2px;
      a {
        padding: 1px 3px;
      }
      &:hover {
        background: $primary;
        color: #fff;
      }
    }
  }
  &-header {
    //display: flex;
    //justify-content: space-between;
  }
  &-title {
    padding-bottom: 2px;
    margin-bottom: 4px;
    text-align: center;
    border-bottom: 1px solid #e8e8e8;
  }
  &-next {
    position: absolute;
    right: 13px;
  }
  &-prev {
    position: absolute;
    left: 2px;
  }
}
.ui-state {
  &-active {
    background: $primary;
    color: #fff;
    border-radius: 2px;
    padding: 0 2px;
  }
}
.ui-datepicker-next {
  width: 30px;
  background: #f1f1f1;
  cursor: pointer;
  &::after {
    content: '';
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHCAYAAADEUlfTAAAAJUlEQVR42mNgAILy8vL/DLgASBKnApgkVgXIkhgKiNKJ005s4gDLbCZBiSxfygAAAABJRU5ErkJggg==) no-repeat scroll 0 0 transparent !important;
    background-size: contain;
    position: absolute;
    left: 50%;
    margin-left: -4px;
    top: 7%;
    margin-top: 7px;
    width: 10px;
    height: 10px;
  }
  span {
    visibility: hidden;
  }
}
.ui-datepicker-prev {
  left: 10px;
  width: 30px;
  background: #f1f1f1;
  cursor: pointer;
  &::after {
    content: '';
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHCAYAAADEUlfTAAAAJUlEQVR42mNgAILy8vL/DLgASBKnApgkVgXIkhgKiNKJ005s4gDLbCZBiSxfygAAAABJRU5ErkJggg==) no-repeat scroll 0 0 transparent !important;
    position: absolute;
    left: 50%;
    margin-left: -8px;
    top: -3%;
    margin-top: 7px;
    width: 10px;
    height: 10px;
    transform: rotate(180deg);
  }
  span {
    visibility: hidden;
  }
}

@keyframes increase {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
