$fiord: #3d4669;
$oxford_blue: #3b4256;
$chetwode_blue: #80a0d6;
$deep_koamaru: #142973;
$regent_gray: #86909b;
$razzmatazz: #E6005C;
$deep_koamaru: #142973;

$main-topic: #ec9a38;
$news: #6ead80;
$nat-project: #00c675;
$docs: #d3b985;
$method: #6a81d0;
$point-of-view: #a57175;
$int-news: #4aa3d1;
$calendar: #1d97ff;
$mediateka: #42b6c4;

$blue: #0368ae;
$dark: #3b4256;

$primary: #7696ff;
$secondary: #b7b6d7;
$tertiary: #8d8db9;
$table: #dbdaec;

