.fieldsLinker li {
    border: 2px solid black !important;
    width: 0% !important;
    width: 20px !important;
    height: 20px !important;
    position: relative !important;
    border-radius: 50% !important;
}

.FL-left {
    float: none !important;
    width: 20px !important;
}

.FL-mid {
    margin-top: -15px !important;
}

.FL-right {
    float: none !important;
}

.match {
    width: 150px;
    padding: 0;
    margin-left: 100px;
}

    .match > li {
        margin-bottom: 20px;
        list-style: none;
    }

.linematchitem > li, .linematchplace > li {
    background-color: #ededed;
    height: 40px;
    padding: 5px;
}

.left {
    //float: left;
}

.jtk-endpoint {
    // background-color: #000;
    circle {
        fill: transparent;
    }
}
.answers-block {
    //display: flex;
    //flex-direction: row-reverse;
}

.jtk-connector {
    path {
      stroke: #e03571;
      stroke-width: 3;
    }
  }
.modal.step3 {
    display: block;
    &-body {
        text-align: center;
    }
}
#exampleModalCenter {
    opacity: 0;
    pointer-events: none;
    z-index: -1;
    display: block;
    &.active {
        opacity: 1;
        pointer-events: all;
        z-index: 998;
    }
}
  