@import "../_global/fonts";
@import "../_global/colors";

.registration {
  hr {
    margin: 45px 0;
    height: 1px;
    background: #efefef;
  }
  input[type=checkbox] {
    display: none;
    &+ label {
      position: relative;
      &::before {
        position: absolute;
        top: 3px;
        left: -23px;
        content: '';
        width: 15px;
        height: 15px;
        background: #fff;
      }
      &:hover {
        color: darken(#3d4669, 20%);
      }
    }
    &:checked {
      &+ label {
        position: relative;
        &::after {
          position: absolute;
          top: 3px;
          left: -21px;
          content: '';
          background: center no-repeat url(../../img/vector/select_arrow.svg);
          background-size: cover;
          width: 10px;
          height: 10px;
        }
      }
    }
  }
  //input.form-control {
  //  &:required:invalid {
  //    border-color: #dc3545;
  //    padding-right: calc(1.5em + .75rem);
  //    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc354…%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  //    background-repeat: no-repeat;
  //    background-position: center right calc(.375em + .1875rem);
  //    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  //  }
  //  &:valid {
  //    border-color: #28a745;
  //    padding-right: calc(1.5em + .75rem);
  //    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8… 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  //    background-repeat: no-repeat;
  //    background-position: center right calc(.375em + .1875rem);
  //    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  //  }
  //  :required {
  //    padding: .375rem .75rem;
  //    font-size: 1rem;
  //    font-weight: 400;
  //    line-height: 1.5;
  //    color: #495057;
  //    background-color: #fff;
  //    background-clip: padding-box;
  //    border: 1px solid #ced4da;
  //    border-radius: .25rem;
  //    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  //  }
  //}
  .flex {
    display: flex;
    justify-content: space-between;
  }
  .search {
    position: relative;
    &__btn {
      position: absolute;
      top: 0;
      right: 10px;
      width: 35px;
      height: 37px;
      border: none;
      background: transparent;
    }
  }
  .modal-header {
    border-bottom: none;
    .modal-title {
      flex-grow: 1;
      text-align: center;
    }
    .close {
      flex-shrink: 1;
    }
  }

  .form-control {
    &:focus {
      border-color: #d3e3ff;
      box-shadow: 0 0 0 0.2rem rgba(0, 113, 255, 0.1);
    }
  }
  .align-center {
    margin-left: auto;
    margin-right: auto;
  }

  .form-required {
    position: relative;

    &::after {
      display: table-cell;
      position: absolute;
      width: 10px;
      height: 10px;
      content: '';
      background: no-repeat center url(../../img/vector/star.svg);
      background-size: contain;
      right: 2px;
      top: calc(50% - 5px);
    }
  }

  .form-input-required {
    position: relative;

    &::after {
      display: table-cell;
      position: absolute;
      width: 10px;
      height: 10px;
      content: '';
      background: no-repeat center url(../../img/vector/star.svg);
      background-size: contain;
      right: -23px;
      top: calc(50% - 5px);
    }
  }

  .textarea-required {
    position: relative;

    &::after {
      display: table-cell;
      position: absolute;
      width: 10px;
      height: 10px;
      content: '';
      background: no-repeat center url(../../img/vector/star.svg);
      background-size: contain;
      right: 2px;
      bottom: 40px;
    }
  }
  .arrow {
    position: relative;
    width: 35px;
    height: 35px;
    padding: 5px;
    flex-shrink: 0;
    background: $primary;
    cursor: pointer;
    transition: .2s;
    &--required {
      &:after {
        display: table-cell;
        position: absolute;
        width: 10px;
        height: 10px;
        content: '';
        background: no-repeat center url(../../img/vector/star.svg);
        background-size: contain;
        right: -13px;
        bottom: 13px;
      }
    }

    img {
      transition: .5s;
      transform: rotate(-180deg);
    }
    &.active {
      img {
        transform: rotate(0);
      }
    }
    &:focus, &:hover {
      background: darken($primary, 20%);
    }
  }
  .btn {
    &-create {
      background: $primary;
      color: #fff;
      border-radius: 20px;
      padding: 5px 25px;
      &:focus, &:hover {
        background: darken($primary, 20%);
      }
    }
  }
  .table {
    border: 1px solid #cdd3d9;
    th {
      background: #3F51B5;
      text-align: center;
      color: #fff;
      font-weight: 500;
      border-bottom: none;
      border-top: none;
      vertical-align: middle;
    }
    td {
      border-top: none;
      vertical-align: middle;
    }
    tr:nth-child(2n) {
      background: #f6f6ff;
    }
    tr td:first-child {
      text-align: center;
    }
    .color-blue {
      color: #7d92ff;
    }
  }
  .pagination {
    justify-content: center;
  }

  .checkboxes {
    display: grid;
    grid-template-columns: 1fr 1fr;
    //grid-auto-rows: 2em;
    //grid-gap: .5em;
    padding: 20px 30px 20px 60px;
    background: #e7e6fa;
    .checkbox {
      margin-bottom: .5em;
      margin-right: 1em;
    }
  }
  &__add {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    border-radius: 15px;
    border: none;
    background: transparent;
    outline: none;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    color: #08064c;
    img {
      width: 20px;
      margin-right: 5px;
    }
    &:hover, &:focus, &:active {
      background: #f2f2f2;
      outline: none;
    }

  }
  &__download {
    padding: 3px 5px;
    border-radius: 15px;
    img {
      margin-left: 5px;
    }
    &:hover, &:focus, &:active {
      background: #f2f2f2;
    }
  }

  .custom-radio .custom-control-input:checked ~ .custom-control-label {
    &::after {
      top: 4px;
      left: -20px;
      border-radius: 50%;
      width: .5rem;
      height: .5rem;
      background: #7d92ff;
    }
    &::before {
      border: #adb5bd solid 1px;
      background-color: #fff;
    }

  }

  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: #fff;
    background-color: #fff;
  }

  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 113, 255, 0.1);
  }
  .form-control.is-valid, .was-validated .form-control:valid {
    //border: #adb5bd solid 1px;
  }
  input[type=checkbox]+label.custom-control-label::before {
    position: absolute;
    top: 0;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    border: none;
  }
  .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    //background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='#7696ff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
    // top: 6px;
  }
  @media all and (max-width: 1200px) {
    .col-md-7 {
      width: 100%;
      max-width: 100%;
    }
  }
  @media all and (max-width: 992px) {
    .checkboxes {
      grid-template-columns: 1fr;
    }
  }
  @media all and (max-width: 576px) {
    .text-right {
      text-align: left !important;
    }
  }
}

.checked-profile:after {
  position: absolute;
  top: 5px;
  left: -21px;
  content: '';
  background: center no-repeat url(../../img/vector/select_arrow.svg);
  background-size: cover;
  width: 10px;
  height: 10px;
}