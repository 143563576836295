.swiper-button-next-op,
.swiper-button-prev-op,
.swiper-button-next-bs,
.swiper-button-prev-bs,
.swiper-button-next,
.swiper-button-prev {
  cursor: pointer;
}

.swiper-button {
  transition: all .2s;

  &:hover {
    opacity: .5;
  }
}

.slider-btns-wrapper {
  position: absolute;
  top: calc(50% - 10px);
  left: 15px;
  right: 15px;

  &__prev, &__next {
    position: absolute;
    left: 0;
    z-index: 100;
    cursor: pointer;

    svg {
      width: 11px;
      height: 20px;
      fill: #071959;
    }
  }

  &__next {
    left: auto;
    right: 0;
  }
}