@import "../_global/fonts";
@import "../_global/colors";

.steps {
  display: flex;
  justify-content: center;
  align-items: center;

  &__line {
    position: relative;
    margin-top: -20px;
    width: 200px;
    max-width: 45%;
    height: 1px;
    border-bottom: 1px dashed #b7b6d7;

    &:after {
      content: '';
      position: absolute;
      top: -6px;
      right: 0;
      width: 0;
      height: 0;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-left: 6px solid #b7b6d7;
    }
  }

  &__step {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 10px;
    &:hover, &:focus {
      .steps__circle {
        background: #c5d2ff;
        color: #fff;
        border: 1px solid #c5d2ff;
      }
    }
    &.active {
      .steps__circle {
        border: 1px solid $primary;
        background: $primary;
        color: #fff;
      }
      .steps__title {
        color: $primary;
        font-weight: bold;
      }
    }
  }

  &__circle {
    margin-top: 15px;
    width: 54px;
    height: 54px;
    color: #b7b6d7;
    border: 1px solid #b7b6d7;
    border-radius: 50%;
    background: #fff;
    font-size: 28px;

    display: flex;
    align-items: center;
    justify-content: center;

    //transition: .2s;
  }
  &__title {
    margin-top: 10px;
    font-weight: bold;

  }
}

.create-education {
  .field {
    &__row {
      display: flex;
      margin-top: 10px;
    }
  }

  .flex-end {
    &-y {
      align-items: flex-end;
    }

    &-x {
      justify-content: flex-end;
    }

  }

  .w-90 {
    width: 90%;
  }

  .file-upload {
    position: relative;
    top: 0;
    left: 0;

    input[type=file] {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      cursor: pointer;
    }

    width: 164px;
    height: 35px;
    background: #201c4e;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    cursor: pointer;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    padding-right: 5px;

    &:hover {
      background: lighten(#201c4e, 20%);
    }

    &__img {
      width: 18px;
      height: 18px;
      margin-right: 5px;
    }

    .error {
      position: absolute;
      left: -235px;
      color: red;
    }

    ul {
      position: absolute;
      top: 8px;
      left: -422px;
      color: red;
    }
  }

  .field {
    &__button {
      position: absolute;
      top: 0;
      right: -50px;
      margin-left: 10px;
      width: calc(2.25rem + 2px);
      height: calc(2.25rem + 2px);
      border-radius: 50%;
      font-size: 30px;
      background: #fff;
      border: 2px solid #dbdbed;
      line-height: 30px;
      transition: .2s;

      &--del {
        background-image: url("../../img/raster/close.png");
        background-position: center center;
        background-repeat: no-repeat;
        cursor: pointer;

        &:hover {
          //background: #edd0dc;
          border: 2px solid #edd0dc;
        }
      }
    }
  }

  .form-control {
    height: 50px;
  }

  .client-files {
    max-width: fit-content;

    .field__button {
      top: -7px;
    }
  }

  .client-file {
    position: relative;
    height: 50px;

    .error {
      position: absolute;
      top: 0;
      right: -125%;
      color: red;
    }
  }

  .video-operator {
    video {
      margin-top: 1em;
      max-width: 85%;
      width: 600px;
    }
  }

  .doc-operator {
    margin-top: 1em;
    margin-bottom: 5em;
    list-style-type: none;

    li {
      color: $primary;
      background: #e1e8ff;
      padding: 10px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      margin-left: -40px;

      a {
        color: #0b3e6f;
      }
    }
  }

  &__menu {
    display: flex;
    border: 1px solid #dadaed;
    margin-top: 25px;
    a {
      width: 33.33333333%;
    }

    .li {

      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 55px;
      padding: 10px 15px;
      cursor: pointer;
      border-right: 1px solid #f1f1f1;

      &:not(:last-child) {
        border-right: 1px solid #dadaed;
      }

      &.active {
        background: $primary;
        color: #fff;
      }

      &--sub {
        width: 33.333333333333%;
        a {
          width: 100%;
          text-align: center;
        }
        &:hover .sub {
          display: block;
        }
      }
    }

    .sub {
      position: absolute;
      z-index: 2;
      top: 100%;
      left: 0;
      width: 100%;
      display: none;
      color: #3d4669;
      background: #fff;
      border: 1px solid #dadaed;

      &__li {
        text-align: center;
        padding: 5px;

        &:hover {
          background: $primary;
          color: #fff;
        }
      }

      a {
        width: 100%;
      }
    }
  }

  &__title {
    margin-top: 50px;
    margin-bottom: 30px;
    background: $primary;
    padding: 5px 10px;
    color: #fff;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    opacity: 1;
    &.disabled {
      opacity: 0;
      pointer-events: none;
    }
  }
  &__subtitle {
    margin-top: 30px;
    background: #cdd3ea;
    padding: 5px 10px;
    // color: #fff;
    // text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
  }
  &__total {
    margin: 30px 0;
    background: #17184c;
    padding: 5px 10px;
    color: #fff;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
  }

  &__video {
    position: relative;
    margin-right: 10px;
    width: 250px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #eff2fd;
    border-radius: 5px;

    video {
      width: 100%;
    }

    .error {
      position: absolute;
      top: 8px;
      right: -200px;
      color: red;
    }
  }
  &__videoBlock {
    word-wrap: break-word;
    width: 100%;
    padding: 10px;
  }

  &__input {
    margin-top: 1.54em;
    flex-grow: 2;
  }
}
.form-submit {
  height: 40px;
  width: 140px;
  margin: 0 10px;
  border: none;
  background: $primary;
  border-radius: 20px;
  font-size: 13px;
  color: #fff;
  font-weight: bold;
  outline: none;
  &:hover, &:focus {
    background: darken($primary, 10%);
  }

  &--dark {
    background: #17184b;
    &:hover, &:focus {
      background: lighten(#17184b, 10%);
    }
  }
  &--publish {
    cursor: pointer;
    background: #ff7e7e;
    &:hover, &:focus {
      background: darken(#ff7e7e, 10%);
    }
  }

}
.form-show {
  padding: 9px 20px;
  border: none;
  background: #e0e0e1;
  border-radius: 20px;
  font-size: 13px;
  //color: #fff;
  font-weight: bold;
  outline: none;
  margin-right: 20px;
  &:hover, &:focus {
    background: darken(#e0e0e1, 10%);
    //color: #fff;
  }
}
.program {
  form {
    display: flex;
    flex-flow: column nowrap;
  }
  .form-order-flex {
    display: flex;
    justify-content: space-between;
    order: 98;
    padding: 20px 0;
    border-top: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;
  }
  .expertise.custom-input-file {
    order: 99;
    position: relative;
    text-align: center;
    margin-top: 40px;
    font-size: 16px;
    input {
      opacity: 0;
      height: 1px;
    }
    label::after {
      content: '';
      position: absolute;
      z-index: -1;
      display: table;
      top: -9px;
      left: calc(50% - 130px);
      width: 260px;
      height: 40px;
      background: #fff;
      border: 2px solid grey;
      border-radius: 30px;
      padding-top: 9px;
      &:hover {
        border: 2px solid blue;
      }
    }
  }
  .js-file-name {
    order: 100;
  }
}

.ok-icon {
  position: absolute;
  top: 10px;
  left: 10px;
  background: url(../../img/vector/ok.svg) no-repeat;
  width: 25px;
  height: 25px;
  background-size: cover;
}

@media all and (max-width: 650px) {
  .create-education {
    &__menu {
      flex-flow: column nowrap;

      .li {
        width: 100%;
      }
    }

    .field__row {
      flex-flow: column nowrap;
      align-items: flex-start;
    }

    &__video {
      margin-bottom: 20px;
    }

    &__input {
      width: 100%;
    }
  }
}