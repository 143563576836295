@import "../_global/fonts";
@import "../_global/colors";

.layout {
  position: relative;
  max-width: 2000px;
  display: flex;
  font-family: $arimo;

  img {
    max-width: 100%;
    max-height: 100%;
    border-radius: unset;
  }

  button {
    cursor: pointer;
    outline: none;
  }

  .menu {
    height: 100vh;
    padding: 12px;
    display: flex;
    flex-flow: column nowrap;
    background: url("../../img/top-bg.svg");

    &__content{
      opacity: 1;
      display: block;
       &_hide{
         transition: opacity 0.5s ease-out;
         opacity: 0;
       }
    }

    &__burger {
      cursor: pointer;
      &._right {
        margin-left: auto;
      }
    }
    &__logo {
      margin-top: 5px;
      align-self: center;
      background: #DEDB79;
      border-radius: 33px;

      &-img {
        width: 87px;
        height: 106px;
      }

      &-text {
        margin-top: 15px;
        //font-family: 'Blogger Sans', sans-serif;
        font-weight: 400;
        color: #fff;
        font-family: Montserrat, serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        color: #00148D;
        padding: 11px 15px 14px 25px;

        strong {
          font-weight: 700;
        }
      }
    }

    &__sections {
      margin: 100px 25px;
      padding: 22px 16px;
      background: #FFFFFF;
      border: 4px solid #7B97F8;
      box-sizing: border-box;
      border-radius: 10px;
      //font-family: Montserrat, serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 112.9%;
      /* or 16px */
      color: #33438B;
    }

    &__section {
      display: flex;
      cursor: pointer;

      &:not(:last-child) {
        padding-bottom: 17px;
      }

      &-img {
        width: 21px;
        height: 21px;
        margin-right: 15px;
        fill: #f0ad4e !important;
      }

      &-text {
        font-family: $arimo;
        color: #33438B;
        font-weight: 500;
        font-size: 16px;
      }
    }

    &.movable {
      position: fixed;
      top: 0;
      left: 0;
      width: 300px;
      transition: .5s;
      z-index: 1;

      &.hide {
        left: -255px;
      }
    }
  }

  .content {
    flex-grow: 2;

    &.movable {
      margin-left: 300px;

      &.hide {
        margin-left: 45px;
      }
    }

    .account {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-end;
      align-items: center;
      padding: 10px;
      box-shadow: 1px 1px 3px rgba(212, 212, 218, 0.75);

      &__profile {
        display: flex;
        align-items: center;
        font-size: 14px;
      }

      &__logo {
        width: 66px;
        height: 66px;
        margin-right: 10px;
        border-radius: 50%;
      }

      &__name {
        color: #363963;
        line-height: 1.5em;
      }

      &__role {
        color: $primary;
      }

      &__button {
        width: 109px;
        height: 38px;
        margin-left: 50px;

        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        background-color: $primary;
        outline: none;
        border: none;
        border-radius: 20px;
        //transition: .2s;
        &:hover {
          background: darken($primary, 10%);
        }
      }
    }

    .breadcrumps {
      display: flex;
      margin: 20px;

      .crump {
        color: $secondary;
        margin-right: 5px;

        &:hover {
          text-decoration: underline;
        }

        &.active {
          color: #8c8ca5;
        }
      }
    }

    .errors, .tips {
      margin-top: 15px;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      border: 1px solid #d2d2d2;
      padding-top: 12px;

      &__header {
        color: $tertiary;
        font-size: 13px;
        font-weight: 700;
        margin-bottom: 12px;
      }

      &__empty {
        text-align: center;
        color: $tertiary;
        font-size: 13px;
        margin-bottom: 10px;
      }

      &__item {
        &:not(:last-child) {
          margin-bottom: 5px;
        }

        display: flex;
        background: #f5a83b;
        padding: 20px 7px;
        font-size: 13px;
        color: #fff;
      }

      &__image {
        width: 25px;
        height: 25px;
        margin-right: 12px;
      }
    }

    .errors {
      &__item {
        background: #f5a83b;
      }
    }

    .tips {
      &__item {
        background: $tertiary;
      }
    }

    .buttons-control {
      position: relative;
      display: flex;
      margin-bottom: 100px;
      justify-content: center;

      &__button {
        margin-right: 15px;
        padding: 10px 15px;
        border-radius: 20px;
        font-size: 12px;
        font-weight: 700;

        &--clear {
          background: transparent;
          color: #363963;
          border: 2px solid #dbdbed;

          &:hover {
            background: #dbdbed;
          }
        }

        &--save {
          background-color: #17184d;
          color: #fff;
          border: 2px solid #17184d;
          user-select: none;

          &:hover {
            background: lighten(#17184d, 20%);
            border: 2px solid lighten(#17184d, 20%);
          }
        }
      }

    }
  }
}

.pre-tip {
  position: fixed;
  z-index: 1;
  bottom: 51px;
  right: 25px;
  color: #f74a20;
  font-weight: bold;
  padding: 11px 16px;
  background: #ffffff;
  border-radius: 24px;
  font-size: 15px;
  opacity: 0;
  transition: opacity .5s;
  pointer-events: none;
  border: 4px solid #ff4c00;

  &.active {
    animation: opacity 5s forwards;
  }
}


@keyframes opacity {
  0% {
    opacity: 0;
  }
  10%, 90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes hide {
  0% {
    opacity: 0;
  }
  100% {
    opacity:1;
  }
}

@media all and(max-width: 650px) {
  .layout {
    .menu.movable {
      position: absolute;
      min-height: 100%;
      height: auto;
    }
    .content.movable {
      margin-left: 45px;
    }
  }

}