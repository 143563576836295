@import "../_global/colors";
.bank-data {
  margin-bottom: 50px;
  input[type=text] {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    margin-bottom: 25px;
  }
  textarea {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    margin-bottom: 25px;
  }
  .plus {
    width: 20px;
  }
  .link {
    display: flex;
    align-items: center;
  }
  .right {
    display: flex;
    justify-content: flex-end;
    text-align: right;
  }
  .center {
    text-align: center;
  }
  .circle {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d0d0d0;
    border-radius: 50%;
    margin-left: 7px;
  }
  .bank-program {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border: 1px solid #d4d4d4;
    margin: 20px 0;
  }
  .name {
    margin-bottom: 10px;
    font-size: 20px;
  }
  .link {
    color: #6e6faf;
  }
  .btn {
    background: #17184c;
    color: #fff;
    border-radius: 20px;
    padding: 5px 25px;
    &:hover {
      background: lighten(#17184c, 20%);
    }
  }
  .bordered {
    padding: 10px;
    border: 1px solid #dedede;
    margin-top: 50px;
    margin-bottom: 20px;
  }
  .icons {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: -10px;
    margin-right: -10px;
  }
  .icon {
    width: 20px;
    height: 20px;
    margin-bottom: 5px;
  }
}