.lds-dual-ring {
  position: absolute;
  top: 10px;
  left: 56px;
  display: inline-block;
  width: 20px;
  height: 20px;
  &:after {
    content: " ";
    display: block;
    width: 17px;
    height: 17px;
    margin: 1px;
    border-radius: 50%;
    border: 3px solid #ffffff;
    border-color: #ffffff transparent #ffffff transparent;
    opacity: 0;
  }
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
    opacity: 1;
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes opacityUp {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes opacityDown {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.form-submit {
  &--publish {
    position: relative;
    &.active {
      background: #7696ff;
      transition-delay: 1.2s;
      .lds-dual-ring:after {
        animation: lds-dual-ring 1.2s .2s linear forwards;
      }
      .published {
        opacity: 0;
        animation: .2s opacityUp 1.2s forwards;
      }
      .unpublished {
        opacity: 0;
        //animation: .5s opacityDown forwards;
      }
    }
    &.disabled {
      background: #7696ff;
      &:focus, &:hover {
        background: #7696ff;
      }
    }
  }
  .unpublished {
    position: absolute;
    top: 10px;
    left: 18px;
    opacity: 1;
  }
  .published {
    position: absolute;
    top: 10px;
    left: 16px;
    opacity: 0;
  }
}
