.preview {
  h5 {
    text-align: center;
    span {
      font-size: .8em;
    }
  }
  h6 {
    text-align: center;
  }
  table {
    border: 1px solid;
    border-collapse:collapse;
    th, td {
      padding: .5em;
      border-left: 1px solid;
    }
    tr {
      border-bottom: 1px solid;
    }
    th {
      background: #c3c0e4;
    }
  }
  &__date {
    text-align: center;
  }
  &__section {
    margin-bottom: 2em;
  }
  &__author {
    text-align: right;
  }
  &__competetion {
    border: 1px solid;
    li {
      border-left: 1px solid;
      margin-bottom: 0;
      padding: .5em;
    }
  }
  .custom {
    padding-left: 1em;
    &-ul {
      display: inline-block;
      padding-left: 1em;
      li {
        list-style: none;
      }
    }
  }
  .flex {
    display: flex;
    justify-content: flex-end;
  }
}


