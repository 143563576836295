.header {

  &__main-wrapper {
    background: #1f3686;
  }

  &__upper-block {
    height: 157px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__logo-block {
    width: 460px;
    position: relative;
  }

  &__main-logo {
    fill: #fff;
    width: 365px;
    height: 100px;
  }

  &__magazine-text {
    color: #80a0d6;
    font-size: 14px;
    font-family: $roboto;
    font-weight: 500;
    line-height: 1.29;
    text-transform: uppercase;
    text-align: right;
    position: absolute;
    top: 5px;
    right: 0;
  }

  &__magazine-number {
    color: #80a0d6;
    font-family: $roboto;
    font-size: 16px;
    font-weight: 700;
    text-align: right;
    line-height: 1.3;
    position: absolute;
    right: 0;
    bottom: 5px;
  }

  &__magazine-number-year {
    color: #80a0d6;
    font-size: 20px;
    font-weight: 700;
    line-height: 1;
  }

  &__eagle-icon {
    display: inline-block;
    @include retina-sprite($eagle-group);
  }

  &__right-block {
    width: 37%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    justify-content: center;
    height: 100px;
  }

  &__nav {
    text-align: right;
    display: flex;
    margin-bottom: 25px;
  }

  &__nav-link {
    text-align: center;
    margin-left: auto;
    color: #80a0d6;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.35;
    transition: all .2s;

    &:hover {
      color: #fff;
      text-decoration: none;
    }
  }


  &__search-row {
    position: relative;

    input::placeholder {
      color: #80a0d6;
    }
  }

  &__search {
    width: 100%;
    height: 44px;
    border: 2px solid #32499a;
    border-radius: 4px;
    background-color: #142973;
    padding: 0 10px;
    color: #fff;
    outline: none;
    padding-right: 30px;
    
    &-submit {
      background-color: #142973;
    }

  }

  &__lens-glass {
    fill: #80a0d6;
    width: 24px;
    height: 24px;
    position: absolute;
    right: 6px;
  }

  &__lens-glass-mobile {
    cursor: pointer;
    fill: #80a0d6;
    width: 24px;
    height: 24px;
  }

  &__hamburger {
    fill: #fff;
    cursor: pointer;
    transition: all .2s;

    &:active {
      fill: #ec9a38;
    }
  }

  &__mobile-block {
    display: none;
  }

  &__sections {
    height: 54px;
    background: #071959;
    text-align: center;
    color: #fff;
  }


  &__sections-link {
    color: inherit;
    font-size: 14px;
    font-weight: 400;
    transition: all .2s;
  }

  &__links-colors {
    $colors: $main-topic, $news, $nat-project, $docs, $method, $point-of-view, $int-news, $calendar;
    //$colors: #ec9a38, #6ead80, #6ead80, #d3b985, #6075be, #a57175, #a78dc2;

    @each $color in $colors {
      $i: index($colors, $color);
      &:nth-of-type(#{$i}) {
        &.active {
          color: $color;
          //pointer-events: none;
        }
        &:hover {
          color: $color;
        }
      }
    }
  }

  &__swiper-wrapper {
    //display: none;
    visibility: hidden;
    margin-top: -54px;
  }

  &__sections-scroll {
    height: 54px;
    background: #071959;
    color: #ffffff;
    position: relative;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      height: 100%;
      width: 100px;
      background: linear-gradient(to left, rgba(255, 255, 255, 0) 0%, rgba(7, 25, 89, 1) 80%);
      z-index: 10;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      height: 100%;
      width: 100px;
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(7, 25, 89, 1) 80%);
      z-index: 10;
    }

    .swiper-button-prev {
      width: 20px;
      height: 100%;
      display: flex;
      align-items: center;
      background: none;
      fill: #fff;
      top: 0;
      margin-top: 0;
      z-index: 11;
    }

    .swiper-button-next {
      width: 20px;
      height: 100%;
      display: flex;
      align-items: center;
      background: none;
      fill: #fff;
      top: 0;
      margin-top: 0;
      z-index: 11;
    }

    .swiper-slide {
      //width: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      text-align: left;

      &:last-of-type {
        margin-right: 70px;
      }

      &:first-of-type {
        margin-left: 70px;
      }
    }

    $map: (
            1: 140px,
            2: 90px,
            3: 250px,
            4: 120px,
            5: 120px,
            6: 130px,
            7: 210px,
            8: 120px,
            9: 100px,
    );

    @each $element, $size in $map {
      .swiper-slide_#{$element} {
        width: $size;
      }
    }

    .header__sections-link {
      font-size: 14px;
      font-weight: 700;
    }
  }

  &__swiper-arr {
    width: 11px;
    height: 20px;

  }
}

@media screen and (max-width: 1100px) {
  .header {
    &__swiper-wrapper {
      //display: block;
      visibility: visible!important;
    }

    &__sections {
      //display: none;
      visibility: hidden;
    }
  }
}

@media screen and (max-width: 991px) {
  .header {
    &__upper-block {
      height: 130px;
    }

    &__logo-block {
      width: 285px;
    }

    &__main-logo {
      width: 213px;
      height: 56px;
    }

    &__magazine-text {
      font-size: 9px;
      font-weight: 500;
      line-height: 1;
      top: 2px;
    }

    &__magazine-number {
      font-size: 12px;
      font-weight: 700;
      line-height: 1;
    }

    &__magazine-number-year {
      font-size: 12px;
      font-weight: 700;
      line-height: 1;
      bottom: 0;
    }

    &__eagle-block {
      padding: 0 20px;
    }

    &__right-block {
      width: 50%;
    }

    &__nav {
      margin-bottom: 15px;
    }

    &__nav-link {
      font-size: 13px;
    }

    &__search {
      height: 40px;
    }
  }
}

@media screen and (max-width: 749px) {
  .header {
    &__upper-block {
      height: 130px;
    }

    &__logo-block {
      width: 220px;
    }

    &__magazine-text {
      width: 85px;
      top: -5px;
      right: 6px;
    }

    &__magazine-number {
      bottom: -20px;
      right: 6px;
      font-size: 16px;
      font-weight: 700;
      line-height: 1.04;
    }

    &__magazine-number-year {
      display: inline-block;
      font-size: 16px;
      font-weight: 700;
      line-height: 1.04;
    }

    &__eagle-block {
      display: none;
    }

    &__right-block {
      display: none;
    }

    &__nav {
      margin-bottom: 15px;
    }

    &__nav-link {
      font-size: 13px;
    }

    &__search {
      height: 40px;
    }

    &__mobile-block {
      display: block;
    }

    &__hamburger {
      margin-bottom: 25px;
    }

  }

}

@media screen and (max-width: 575px) {
  .header {
    &__swiper-wrapper {
      display: none;
    }
    &__sections {
      display: none;
    }
  }
}
