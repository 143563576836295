@import "../_global/colors";
body {
  //overflow: visible !important;
}

.test-builder {
  input {
    margin-left: 5px;
    padding: 5px;
    outline: none;
    border-radius: 5px;
    border: 1px solid #d1d1d1;
    transition: .5s;

    &:focus, &:active {
      color: #495057;
      //background-color: #fff;
      border-color: #7696ff;
      //border: none;
      outline: 0;
      box-shadow: none;
    }
  }

  input[type=text] {
    height: 39px;

    &.input-text {
      height: 28px;
    }
  }

  input[type=file] {
    width: 265px;
  }

  input[type=checkbox] {
    display: none;

    & + label {
      position: relative;

      &::before {
        position: absolute;
        top: 16px;
        left: 0;
        content: '';
        width: 25px;
        height: 25px;
        background: #fff;
        border: 1px solid #7696ff;
      }

      &:hover {
        color: darken(#3d4669, 20%);
      }
    }

    &:checked {
      & + label {
        position: relative;

        &::after {
          position: absolute;
          top: 22px;
          left: 5px;
          content: '';
          background: center no-repeat url(../../img/vector/select_arrow.svg);
          background-size: cover;
          width: 13px;
          height: 13px;
        }
      }
    }
  }

  div[class^="test-block-"] {
    position: relative;
    margin-bottom: 50px;
    margin-top: 40px;
    margin-right: 50px;
    padding: 20px;
    //border: 2px solid #e8e8e8;
    border-radius: 5px;
    background: whitesmoke;
  }

  #clear_btn {
    border: none;
    margin-left: 15px;
    background: #ffc5ca;
    outline: none;
    padding: 4px 20px;
    border-radius: 3px;
    font-weight: bold;
    font-size: 12px;
    &:hover, &:active {
      background: #e2a0aa;
    }
  }


  .question-type {
    margin-left: 5px;
    width: 250px;
  }

  .question-block {
    position: relative;
    margin: 20px 50px 40px 0;
    padding: 15px;
    background: #f5f5f5;
    border-left: 2px solid #e6e6e6;
  }

  .question-input {
    width: 100%;
    margin-bottom: 20px;
  }

  .input-narrow:not(:first-child), .input-file {
    margin: 0 5px;
    //width: 50px;
  }

  .answers-block {
    width: fit-content;
    position: relative;
  }

  .answer__text {
    height: 100%;
    margin: 0 8px;
    display: flex;
    align-items: center;
    font-size: 16px;
    &::-webkit-scrollbar-track
    {
      //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      border-radius: 10px;
      background-color: transparent;
    }

    &::-webkit-scrollbar
    {
      width: 12px;
      background-color: rgba(0,0,0,.1);
    }

    &::-webkit-scrollbar-thumb
    {
      border-radius: 10px;
      //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      background-color: #7696ff;
    }
  ;
  }

  .answer-block {
    position: relative;
    height: 100px;
    //width: 150px;
    width: 300px;
    display: flex;
    align-items: center;


    &--left {
      &:after {
        content: '';
        z-index: 99;
        position: absolute;
        top: calc(50% - 16px);
        right: -16px;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: #fff;
        border: 2px solid #7696ff;
      }
    }

    &--right {
      &:after {
        content: '';
        z-index: 99;
        position: absolute;
        top: calc(50% - 16px);
        left: -16px;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: #fff;
        border: 2px solid #7696ff;
      }
    }
    &.active {
      &:after {
        border: 2px solid #ff8f00;
      }
    }
  }

  .answer-image {
    margin: 0 5px;
    width: 50px;
  }

  .answer-arrow {
    white-space: nowrap;
  }

  .answers-wrapper {
    margin-top: 25px;
    .clear_lines {
      border: none;
      background: #dcdcf1;
      color: #53548c;
      padding: 4px 20px;
      border-radius: 3px;
      font-weight: bold;
      font-size: 12px;
      &:hover, &:active {
        background: #ffc5ca;
        color: #24243c;
      }
    }
  }

  .answer-wrapper {
    position: relative;
    //margin-top: 20px;
    &:first-child {
      margin-top: -20px;
    }
  }

  .input-label {
    display: flex;
    align-items: center;
  }

  .input-order {
    width: 50px;
  }

  .input-text {
    margin-left: 30px;
  }

  .fieldsLinker-wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .remove-answer.field__button--del {
      right: -28px;
      top: 0px;
    }
  }

  .test-name {
    width: 100%;
    background: transparent;
    border: none;
    border-bottom: 2px solid #aaaaaa;
    border-radius: 0;
    font-size: 20px;

    &:active, &:focus {
      outline: none;
    }
  }

  .test-tooltip {
    position: relative;
    display: inline;
    margin-left: 10px;
    width: 25px;

    img {
      width: 20px;
      background: #c2cff9;
      padding: 2px;
      border-radius: 50%;
    }

    &--text {
      position: absolute;
      z-index: 2;
      top: -10px;
      left: 34px;
      width: 300px;
      padding: 10px;
      background: #f8f9ff;
      color: #4c4c4c;
      border-radius: 5px;
      border: 2px solid #c2cff9;
      opacity: 0;
      pointer-events: none;
      transition: .2s;
    }

    &:hover {
      .test-tooltip--text {
        opacity: 1;
      }
    }
  }

  .add-test {
    width: 150px;
    margin: 20px 0;
    padding: 10px;
    background: #7696ff;
    text-align: center;
    border-radius: 5px;
    color: #fff;
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, .3);
    cursor: pointer;

    &:hover {
      background: #5e78cc;
    }
  }

  .add-answer {
    width: 150px;
    text-align: center;
    padding: 9px;
    margin-top: 35px;
    margin-bottom: 25px;
    background: #eeeeee;
    color: #7696ff;
    border-radius: 5px;
    box-shadow: 0 0px 2px 1px rgba(0, 0, 0, .15);
    cursor: pointer;

    &:hover {
      background: #ffffff;
    }
  }

  .add-question, .add-question-twig {
    text-align: center;
    width: 200px;
    padding: 9px;
    margin-top: 40px;
    margin-right: 50px;
    color: #7696ff;
    border: 2px solid;
    //background: #dfe6ff;
    border-radius: 5px;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, .15);
    cursor: pointer;

    &:hover {
      background: #fff;
    }
  }

  .field__button {
    border-radius: 5px;
  }

  .field__button--medium {
    right: -30px;
    top: -20px;
    background: transparent url(../../img/raster/close.png) no-repeat center center;
    background-size: 10px;
    border-radius: 5px;
    width: 30px;
    height: 30px;

  }

  .remove-answer.field__button--del {
    right: -25px;
    top: 24px;
    background: transparent url(../../img/raster/close.png) no-repeat center center;
    background-size: 10px;
    border-radius: 5px;
    width: 22px;
    height: 22px;
  }

  &.answer-builder {
    div[class^="test-block-"] {
      margin: 20px;
      padding: 0;
      background: transparent;
    }

    .test-title {
      margin-bottom: 20px;
      font-size: 20px;
      font-weight: bold;
    }

    .answer-header {
      background: #d5d5e6;
      padding: 10px 15px;
      margin-bottom: 2px;
      font-weight: bold;
    }

    .answer {
      margin-bottom: 30px;
    }

    .answers-block-twig {
      margin-bottom: 30px;
      background: #e7e7fa;
      padding: 30px;

      .answer-wrapper {
        margin-top: 0;
        margin-left: 80px;
      }

      input[type=checkbox] {
        & + label {
          &:before {
            top: 0px;
            left: -34px;
          }

          &:after {
            top: 6px;
            left: -28px;
          }

        }
      }

      input[type=radio] {
        display: none;

        & + label {
          position: relative;

          &::before {
            position: absolute;
            top: 0px;
            left: -40px;
            content: '';
            width: 25px;
            height: 25px;
            background: #fff;
            border: 1px solid #7696ff;
            border-radius: 50%;
          }

          &:hover {
            color: darken(#3d4669, 20%);
          }
        }

        &:checked {
          & + label {
            position: relative;

            &::after {
              position: absolute;
              top: 7px;
              left: -33px;
              content: '';
              background: #7696ff;
              width: 11px;
              height: 11px;
              border-radius: 50%;
            }
          }
        }
      }
    }

    .check {
      text-align: center;
      margin-bottom: 50px;

      &-button {
        background: #17174c;
        border: none;
        border-radius: 20px;
        font-size: 13px;
        color: #fff;
        font-weight: bold;
        outline: none;
        &:hover, &:active {
          background: lighten(#17174c, 10%);
        }
      }
    }
  }
}
.connection-block {
  display: flex;
  justify-content: center;
}
.attestation {
  margin: 30px 0 40px 0;
  &-table {
    display: flex;
    padding: 20px;
    border: 1px solid #d2d2d2;
  }
  &__col {
    width: 33.333333%;
  }
  &__row {
    text-align: center;
    margin-bottom: 15px;
    &--header {
      color: grey;
    }
    &--ok {
      text-align: left;
      margin-left: 20px;
    }
    &--left {
      text-align: left;
      margin-left: 20px;
    }
  }
}

//.modal {
//  overflow: visible;
//  max-width: unset;
//  height: unset;
//  img {
//    max-width: 100%;
//  }
//}
.modal-body {
  //text-align: center;
  label {
    text-align: left;
  }
}
.answer-block--right {
  .zoom-in {
    left: 258px;
  }
}
.zoom-in {
  position: absolute;
  width: 40px;
  top: calc(50% - 20px);
  left: -41px;
  cursor: pointer;
  background: #f0f0fd;
  padding: 5px;
  border: 1px solid #d5d5e6;
  &:hover {
    background: #fff;
  }
}
.match {
  //width: unset;
  width: 300px;
  margin-left: 180px;;
  &:first-child {
    margin-left: 0;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-end;
  }
}

.error {
  color: red;
}
.linematchitem > li, .linematchplace > li {
  background: transparent;
}
.uploaded-img {
  object-fit: scale-down;
  object-position: center;
  width: 100%;
}

.circle-styles {
  svg {
    z-index: 1;
  }

  circle {
    fill: transparent;
    z-index: -99;
  }
}

.jtk-connector path {
  stroke: #7696ff;
}

.certificate {
  margin-left: 10px;
  margin-bottom: 50px;
  &__docs {
    border-radius: 5px;
    margin-bottom: 20px;

    a {
      display: flex;
      align-items: center;
      color: #3e3e3e;
      &:hover, &:active {
        text-decoration: underline;
      }
    }
    img {
      width: 40px;
      height: 40px;
    }
  }
  &__sign {
    padding-bottom: 20px;
  }
  &.disabled {
    opacity: 0;
    //filter: grayscale(1);
    //pointer-events: none;
  }
  .file-upload {
    input[type=file] {
      opacity: 0;
    }
    position: relative;
    margin: auto;
    width: 215px;
    height: 34px;
    border: 2px solid #ccd0de;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    cursor: pointer;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    padding-right: 5px;
    &:hover {
      background: $primary;
    }
   span {
     position: absolute;
     top: 5px;
     left: 12px;
     color: #17174b;
     font-size: 14px;
   }
  }

}

.certificate2 {
  margin-left: 10px;
  margin-bottom: 50px;
  margin-top: 35px;
  background: #f1f1f1;
  margin-right: 20px;
  border-top: 3px solid #7696ff;
  &__docs {
    padding: 30px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    a {
      display: flex;
      align-items: center;
      color: #ffffff;
      background: #3F51B5;
      padding: 15px;
      border-radius: 10px;
      &:hover, &:active {
        text-decoration: underline;
      }
    }
    img {
      width: 40px;
      height: 40px;
    }
  }
  &__sign {
    font-size: 16px;
    width: 200px;
    color: #252525;
    margin-right: 20px;
  }
  &.disabled {
    opacity: 0;
    //filter: grayscale(1);
    //pointer-events: none;
  }
  .file-upload {
    input[type=file] {
      opacity: 0;
    }
    position: relative;
    margin: auto;
    width: 215px;
    height: 34px;
    border: 2px solid #ccd0de;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    cursor: pointer;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    padding-right: 5px;
    &:hover {
      background: $primary;
    }
    span {
      position: absolute;
      top: 5px;
      left: 12px;
      color: #17174b;
      font-size: 14px;
    }
  }

}

.table-report {
  width: 100%;
  margin-top: 30px;
  border: 1px solid #f5f5f5;
  tr {
    &:nth-child(2n) {
      background: #f5f5f5;
    }
  }
  th {
    padding: 10px 0 10px 10px;
    text-align: left;
    color: grey;
  }
  td {
    padding: 10px 5px;
  }
  &__status {
    color: $primary;
  }
}
.table-report {
  border: 1px solid #cdd3d9;
  th {
    background: #3F51B5;
    text-align: center;
    color: #fff;
    font-weight: 500;
    border-bottom: none;
    border-top: none;
    vertical-align: middle;
  }
  td {
    text-align: center;
    border-top: none;
    vertical-align: middle;
  }
  tr:nth-child(2n) {
    background: #f6f6ff;
  }
  tr td:first-child {
    text-align: center;
  }
  .color-blue {
    color: #7d92ff;
  }
}
