html, body {
  width: 100%;
  height: 100%;
}

.main-wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.main {
  flex-grow: 1;
}

.header, .main, .footer {
  flex-shrink: 0;
}

.section-title-block {
  min-height: 33px;
}