body {
  font-family: $open-sans;
  font-weight: 400;
  font-size: 16px;
  color: #3d4669;
  background-color: #fff;
  min-width: 320px;
}

.section-wrapper {
  max-width: 1280px;
  margin: auto;
  padding: 0 15px;
  &_md {
    max-width: 900px;
  }
}

[class*='svg-'] {
  display: inline-block;
}

input[type=number] {
  -moz-appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

select {
  width: 100%;
  background: #fff;
  height: 39px;
  border: 1px solid #d1d1d1;
}

ul {

}

li {
  margin-bottom: 5px;
}

button {
  outline: none;
}

.no-wrap {
  white-space: nowrap;
}

.rotate-180 {
  transform: rotate(180deg);
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}


select {
  width: 100%;
  border-radius: 5px;
}

hr {
  border: 0;
  border-bottom: none;
  height: 2px;
  background: #c6d3e5;
  display: block;
  margin-bottom: 30px;
}

img, i {
  border-radius: 5px;
}

.youtube-adaptive {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  //padding-top: 25px;
  width: 100%;
  height: 0;
  background: url("/img/raster/Ripple-1.6s-44px.gif") center no-repeat;
  iframe {
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.up-button {
  position: absolute;
  width: 46px;
  height: 46px;
  border: 2px solid #33499A;
  //border: 2px solid #80a0d6;
  border-radius: 5px;
  right: 29px;
  bottom: 61px;
  text-align: center;
  padding: 14px 0;
  //opacity: .5;

  &:hover {
    cursor: pointer;
  }

  &:hover .arrow, &:active .arrow {
    border: solid rgb(128, 160, 215);
    border-width: 0 5px 5px 0;
  }

  .arrow {
    border: solid rgb(51, 73, 154);
    border-width: 0 5px 5px 0;
    display: inline-block;
    padding: 8px;
  }

  .up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
}

.national-project {
  font-size: 24px!important;
  line-height: 1;
}

.national-project, .calendar {
  padding: 30px 0 60px;
 }

#navbar1, #navbar2 {
  z-index: 100;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.under-sticky {
  padding-top: 54px;
}


@media screen and (max-width: 1450px) {
  .up-button {
    bottom: 10px;
    right: 9px
  }
}

//@media screen and (max-width: 991px) {
//  .up-button {
//    bottom: 30px;
//    right: 15px
//  }
//}
//
//@media screen and (max-width: 576px) {
//  .up-button {
//    bottom: 105px;
//  }
//  .under-sticky {
//    padding-top: 0;
//  }
//}


.archive-wrapper {
  padding: 60px 0;
  background: #eaeff6;
}

.archive-section {
  background: #eaeff5;
  padding: 20px 0 60px;
}

.search-input {
  &:hover, &:active, &:focus {
    background-color: rgb(232, 240, 254) !important;
    color: #3b4256 !important;

    &::placeholder {
      color: #3b4256 !important;
    }

    & + button, button.header__search-submit, button.search__btn {
      background-color: rgb(232, 240, 254);
    }

    & + button svg.header__lens-glass {
      fill: #1f3686;
    }
  }
}